import {
    isNGNTopicPageRedesignEnabled,
    PerthNowSection,
} from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { getTopicPageRouteInfo } from '../topic/get-topic-page-route-info'
import {
    getAFLPageRouteInfo,
    getCricketPageRouteInfo,
    getFlagmantlePageRouteInfo,
    getLiveScoresPageRouteInfo,
    getParalympicsPageRouteInfo,
    getSportsPageRouteInfo,
    getSoccerPageRouteInfo,
} from './pages'
import { getNGNTopicPageRouteInfo } from '../topic/get-ngn-topic-page-route-info'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'

const sportsSeoDescription = 'Sport news and updates from PerthNow'

const sportTopic: Topic = {
    id: 'sport',
    title: 'Sport',
    metadata: {},
    seoTitle: '',
    seoDescription: sportsSeoDescription,
}

const getTopic = (props: {
    topic: string
    titleOveride?: string
    seoDescription?: string
}): Topic => {
    const { topic, titleOveride, seoDescription } = props

    return {
        id: `sport/${topic.toLowerCase()}`,
        title: titleOveride ?? topic,
        parent: sportTopic,
        metadata: {},
        seoTitle: titleOveride ?? topic ?? '',
        seoDescription: seoDescription ?? sportsSeoDescription,
    }
}

export const sportRoutes: StaticRoutes<PerthNowSection> = {
    '/sport': ({ getAdTargeting, store }) => {
        if (isNGNTopicPageRedesignEnabled(store)) {
            return getNGNTopicPageRouteInfo({
                getAdTargeting,
                topic: sportTopic,
                section: 'sport',
            })
        }

        return getSportsPageRouteInfo(getAdTargeting, store, sportTopic)
    },

    '/sport/afl': ({ getAdTargeting, store }) => {
        const topic = getTopic({ topic: 'Afl', titleOveride: 'AFL' })

        if (isNGNTopicPageRedesignEnabled(store)) {
            return getNGNTopicPageRouteInfo({
                getAdTargeting,
                topic,
                section: 'sport',
            })
        }

        return getAFLPageRouteInfo(getAdTargeting, store, topic)
    },

    '/sport/cricket': ({ getAdTargeting, store }) => {
        const topic = getTopic({
            topic: 'Cricket',
            seoDescription: 'Cricket breaking news and updates from PerthNow',
        })

        if (isNGNTopicPageRedesignEnabled(store)) {
            return getNGNTopicPageRouteInfo({
                getAdTargeting,
                topic,
                section: 'sport',
            })
        }

        return getCricketPageRouteInfo(getAdTargeting, topic)
    },

    '/sport/soccer': ({ getAdTargeting, store }) => {
        const topic = getTopic({ topic: 'Soccer' })

        if (isNGNTopicPageRedesignEnabled(store)) {
            return getNGNTopicPageRouteInfo({
                getAdTargeting,
                topic,
                section: 'sport',
            })
        }

        return getSoccerPageRouteInfo(getAdTargeting, topic)
    },

    '/sport/afl/flagmantle': ({ getAdTargeting, resolution, store }) => {
        const topic = getTopic({ topic: 'Afl' })
        // TODO: Update routing for NGN - Outlier tertiary / pseudo topic
        return getFlagmantlePageRouteInfo(
            getAdTargeting,
            store,
            resolution,
            topic,
        )
    },

    '/sport/paralympics': ({ getAdTargeting, store }) => {
        const isCustomParisOlympicsRouteEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'paris-olympics-2024',
        )

        const topic = getTopic({
            topic: 'Paralympics',
            seoDescription: 'Paralympics news and updates from PerthNow',
        })

        if (isNGNTopicPageRedesignEnabled(store)) {
            return getNGNTopicPageRouteInfo({
                getAdTargeting,
                topic,
                section: 'sport',
            })
        }

        if (!isCustomParisOlympicsRouteEnabled) {
            return getTopicPageRouteInfo({
                topic,
                section: 'sport',
                getAdTargeting,
            })
        } else {
            return getParalympicsPageRouteInfo(getAdTargeting, store, topic)
        }
    },
    '/sport/cricket/live-scores': getLiveScoresPageRouteInfo,
}
