import { PerthNowSection } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getPromoLayout } from '../../common/promoLayout'
import { perthNowSectionOverrider } from '../../common/sectionTheme'

export const getSoccerPageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    topic: Topic,
): PageType<PerthNowSection> => ({
    kind: 'page',
    heading: 'Soccer',
    pageType: 'topic',
    pageMeta: {
        description: 'Sport news and updates from PerthNow',
    },
    adTargeting: getAdTargeting('home', 'sport', topic),
    section: 'sport',
    compositions: [
        layout.composition({
            type: 'zeus',
            props: {
                verticalGutters: 'unset',
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'lima',
                        props: {
                            fixedRatios: ['16:9'],
                            initialColumns: 1,
                            finalColumns: 1,
                            fontScale: 2,
                            hideByline: true,
                            verticalSpacing: 'sm',

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/soccer'],
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                includeSubTopics: true,
                                paging: {
                                    page: 1,
                                    pageOffset: 0,
                                    pageSize: 1,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'milo',
                        props: {
                            showLargeCard: false,
                            verticalSpacing: 'md',
                            teaserMode: 'visible',

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/soccer'],
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                includeSubTopics: true,
                                paging: {
                                    page: 1,
                                    pageOffset: 1,
                                    pageSize: 4,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'mobile-mrec-one',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },
                                hideByline: true,
                                verticalSpacing: 'md',
                                teaserMode: 'visible',

                                sectionHeader: {
                                    heading: 'Perth Glory',
                                    headingUrl: '/sport/perth-glory',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/perth-glory'],
                                    // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                    ]),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'mobile-mrec-two',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },
                                hideByline: true,
                                verticalSpacing: 'md',
                                teaserMode: 'visible',

                                sectionHeader: {
                                    heading: 'A-League',
                                    headingUrl: '/sport/a-league',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/a-league'],
                                    // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                    ]),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },
                                hideByline: true,
                                verticalSpacing: 'md',
                                teaserMode: 'visible',

                                sectionHeader: {
                                    heading: 'English Premier League',
                                    headingUrl: '/sport/english-premier-league',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/english-premier-league'],
                                    // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                    ]),
                    layout.component({
                        type: 'taboola',
                        props: {
                            taboolaContainerId:
                                'taboola-below-category-thumbnails',
                            locationType: 'category',
                        },
                    }),
                ],
                sidebar: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            padding: [0, 0, 13, 0],
                            hiddenUntilLoaded: true,
                            slot: {
                                id: 'sidebar-two',
                                size: 'desktopMrecHalfPage',
                            },
                            adType: 'inline',
                        },
                    }),
                    getPromoLayout('sport'),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'juliet',
                            props: {
                                cardType: {
                                    type: 'landscape',
                                    format: 'landscape-common',
                                },
                                removeHorizontalGutters: true,
                                verticalSpacing: 'sm',
                                kickerMode: 'hidden',
                                sectionHeader: {
                                    heading: 'Latest Sport News',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport'],
                                    // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 18,
                                    },
                                },
                            },
                        }),
                    ]),
                ],
            },
        }),
        layout.composition({
            type: 'thor',
            props: {
                hasBackgroundFill: false,
                horizontalGutters: true,
                verticalGutters: false,
            },
            contentAreas: {
                main: [getPromoLayout('sport', { mobileOnly: true })],
            },
        }),
    ],
})
