import React, { useEffect } from 'react'
import {
    ButtonContainer,
    Divider,
    LoginStateInformationContainer,
    PNLoginButtonContainer,
    StyledAppDownloadContainer,
    StyledAuthLink,
    StyledLoginModalContainer,
} from './PNHeaderLoginModalNGN.styled'
import {
    AuthenticationState,
    DataLayerEventName,
    useFeature,
} from '@news-mono/web-common'
import {
    PNLoggedOutButtons,
    PNLoggedOutInformationContainer,
} from './PNHeaderLoginModalLoggedOut'
import { saveOriginUrl, useAuthActions } from '../../../user-registration'
import { useHistory } from 'react-router'
import { useOnClickOutside } from '../../../__helpers/on-click-outside'
import { IconUserLogin } from '../../../icons/IconUserLogin/IconUserLogin'
import {
    PNLoggedInButtons,
    PNLoggedInInformationContainer,
} from './PNHeaderLoginModalLoggedIn'
import { PerthNowButton } from '../../../buttons/PerthnowButton/PerthnowButton'

// Props to pass through from the primary actions level
export interface PNLoginActionLinksProps {
    authState: AuthenticationState
    onEvent: (event: any) => void
}
// Props to pass down to sub-components of the login modal
export interface PNHeaderLoginStateProps {
    authenticationState: AuthenticationState
    hide: () => void
}
export interface PNLoginModalProps {
    buttonProps: {
        onMyAccountClick: () => void
        onLogoutClick: () => void
        onLoginClick: () => void
        onCreateAccountClick: () => void
    }
    modalProps: { show: () => void; hide: () => void }
    isLoggedIn: boolean
    authState: AuthenticationState
    ignoreFade?: boolean
    headerLoginModalContainer: React.MutableRefObject<HTMLElement | null> | null
}

// The top-level of the login, containing the logged in/logged out state buttons, and all
// the handlers for within.
export const PNHeaderLogin: React.FC<PNLoginActionLinksProps> = ({
    authState,
    onEvent,
}) => {
    const history = useHistory()
    const showAppDownloadButton = useFeature('web-app-download')

    const { onLoginClick, onLogoutClick, onRegisterClick } = useAuthActions()
    const isLoggedIn = authState.isLoggedIn
    const headerLoginModalContainer = React.useRef<HTMLElement | null>(null)

    // Show & Hide the Login Modal
    const [isHidden, setHidden] = React.useState(true)
    const hide = React.useCallback(() => {
        setHidden(true)
    }, [])
    const show = React.useCallback(() => {
        setHidden(false)
    }, [])

    useOnClickOutside(
        headerLoginModalContainer,
        () => {
            hide()
        },
        { enabled: !isHidden },
    )

    const handleOnClick = () => {
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: `GetTheFreeAppButton`,
            payload: {
                navName: 'Default.PerthNow.AppButton.NGNLaunch',
                navLocation: 'Header',
                navText: 'Get the FREE App',
                navPath: '/app',
            },
        })
    }

    // Handles whenever the user clicks on the "My account" button while
    // current logged in. This should re-direct them to the account section
    // of TheNightly, however that is awaiting completion.
    const onMyAccountClick = () => {
        hide()
        history.push('/manage-account')
    }

    useEffect(() => {
        if (!isHidden && headerLoginModalContainer.current) {
            console.log('Updating the focus of the login modal!')
            headerLoginModalContainer.current.focus()
        }

        const onEscapePress = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                hide()
            }
        }

        document.addEventListener('keydown', onEscapePress)

        return () => {
            document.removeEventListener('keydown', onEscapePress)
        }
    }, [isHidden, hide])

    return (
        <>
            {showAppDownloadButton && (
                <StyledAppDownloadContainer to={'/app'} onClick={handleOnClick}>
                    <p>Get the FREE App</p>
                </StyledAppDownloadContainer>
            )}
            {isLoggedIn ? (
                <StyledAuthLink
                    id="header-login-icon"
                    onClick={(e) => {
                        e.preventDefault()
                        show()
                    }}
                >
                    <IconUserLogin />
                </StyledAuthLink>
            ) : (
                <PNLoginButtonContainer>
                    <PerthNowButton
                        id="header-login-button"
                        text={'Log in'}
                        action={{
                            type: 'button',
                            onClick: () => {
                                show()
                            },
                        }}
                        type={'tertiary'}
                        size={'S'}
                    />
                </PNLoginButtonContainer>
            )}

            {/* Show the login modal when the login button is clicked */}
            {!isHidden && (
                <PNHeaderLoginModal
                    headerLoginModalContainer={headerLoginModalContainer}
                    buttonProps={{
                        onMyAccountClick,
                        onLoginClick: () => {
                            saveOriginUrl(true)
                            onLoginClick('login-modal')
                        },
                        onLogoutClick: () => {
                            saveOriginUrl(true)
                            onLogoutClick(onEvent)
                        },
                        onCreateAccountClick: () =>
                            onRegisterClick('login-modal', 'web'), //this compoennt doesn't display in the app rendition
                    }}
                    authState={authState}
                    modalProps={{ show, hide }}
                    isLoggedIn={!!isLoggedIn}
                />
            )}
        </>
    )
}

export const PNHeaderLoginModal: React.FC<PNLoginModalProps> = ({
    buttonProps,
    modalProps,
    isLoggedIn,
    authState,
    headerLoginModalContainer,
}) => {
    const {
        onMyAccountClick,
        onLoginClick,
        onLogoutClick,
        onCreateAccountClick,
    } = buttonProps
    const { hide, show } = modalProps

    // Show different states depending on the logged in/logged out state
    const LoginStateInformation = isLoggedIn
        ? PNLoggedInInformationContainer
        : PNLoggedOutInformationContainer

    useEffect(() => {
        if (!headerLoginModalContainer || !headerLoginModalContainer.current)
            return

        const focusableElements =
            headerLoginModalContainer.current.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
            ) as NodeListOf<HTMLElement>

        if (focusableElements.length > 0) {
            const firstFocusableElement = focusableElements[0]
            const lastFocusableElement =
                focusableElements[focusableElements.length - 1]

            const handleTab = (e: KeyboardEvent) => {
                if (e.key === 'Tab') {
                    if (e.shiftKey) {
                        // Shift + Tab
                        if (document.activeElement === firstFocusableElement) {
                            e.preventDefault()
                            lastFocusableElement.focus()
                        }
                    } else {
                        // Tab
                        if (document.activeElement === lastFocusableElement) {
                            e.preventDefault()
                            firstFocusableElement.focus()
                        }
                    }
                }
            }

            document.addEventListener('keydown', handleTab)
            firstFocusableElement.focus()

            return () => {
                document.removeEventListener('keydown', handleTab)
            }
        }
    }, [headerLoginModalContainer])

    return (
        <StyledLoginModalContainer
            isLoggedIn={isLoggedIn}
            tabIndex={-1}
            ref={(el) =>
                headerLoginModalContainer !== null
                    ? (headerLoginModalContainer.current = el)
                    : null
            }
        >
            <LoginStateInformationContainer>
                <LoginStateInformation
                    authenticationState={authState}
                    hide={hide}
                />
            </LoginStateInformationContainer>
            <Divider />
            <ButtonContainer isLoggedIn={isLoggedIn}>
                {isLoggedIn ? (
                    <PNLoggedInButtons
                        onMyAccountClick={onMyAccountClick}
                        onLogoutClick={() => {
                            onLogoutClick()
                            hide()
                        }}
                    />
                ) : (
                    <PNLoggedOutButtons
                        onLoginClick={onLoginClick}
                        onCreateAccountClick={onCreateAccountClick}
                    />
                )}
            </ButtonContainer>
        </StyledLoginModalContainer>
    )
}
