import styled from '@emotion/styled'
import { breakpoint, calcRem } from '@news-mono/component-library'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import { perthNowNGNTextMixin } from '../../../../mixins/TextMixin/TextMixin.theme'
import { IconAlertCircleTN } from '../../../../icons/IconAlertCircleTN/IconAlertCircleTN'

export interface StyledInputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    hasError?: boolean
}

export const StyledNewsletterBannerContainer = styled('div')(() => ({
    backgroundColor: colorsPN.fill.brand.weak,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: `${metricsPN.radius['M']}px`,
    position: 'relative',
    padding: calcRem(metricsPN.spacing['L']),

    [breakpoint('xxs')]: {
        flexDirection: 'row',
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
    },

    [breakpoint('lg')]: {
        display: 'flex',
        flexDirection: 'column',
        height: 'unset',
    },
}))

export const StyledButton = styled('button')({
    ...perthNowNGNTextMixin.label.M.semibold,
    width: '100%',
    height: calcRem(48),
    textAlign: 'center',
    backgroundColor: colorsPN.fill.brand.strong,
    color: colorsPN.text.inverse,
    borderRadius: calcRem(metricsPN.radius['L']),
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
        backgroundImage: `linear-gradient(${colorsPN.fill.hover.default} 0 0)`,
        outline: 'none',
    },
    '&:active': {
        backgroundImage: `linear-gradient(${colorsPN.fill.pressed.default} 0 0)`,
    },
    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['4XS']),
    },
})

export const StyledHeaderContainer = styled('div')(() => ({
    '& > span': {
        ...perthNowNGNTextMixin.title.XL.bold,
    },
}))

export const StyledPNHeading = styled('span')(() => ({
    color: colorsPN.text.brand,
}))

export const StyledDigitalEditionHeading = styled('span')(() => ({
    color: colorsPN.text.primary,
}))

export const StyledTextContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
}))

export const StyledPromoText = styled('span')(() => ({
    ...perthNowNGNTextMixin.body.M.regular,
    color: colorsPN.text.primary,
    display: 'block',
    wordWrap: 'break-word',
    width: '100%',
    whiteSpace: 'normal',
    marginTop: calcRem(metricsPN.spacing['3XS']),
}))

export const StyledInfoText = styled(StyledPromoText)({
    margin: calcRem(metricsPN.spacing['3XS'], 0, metricsPN.spacing['L']),
})

export const StyledButtonContainer = styled('div')(() => ({
    margin: calcRem(
        metricsPN.spacing['S'],
        metricsPN.spacing['L'],
        metricsPN.spacing['L'],
        metricsPN.spacing['L'],
    ),
    textAlign: 'center',
    zIndex: 1,

    [breakpoint('xxs')]: {
        maxWidth: calcRem(260),
    },

    [breakpoint('lg')]: {
        maxWidth: '100%',
    },
}))

export const StyledImageContainer = styled('div')(() => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    borderRadius: `${metricsPN.radius['M']}px`,
    zIndex: 0,

    [breakpoint('xxs')]: {
        order: 2,
    },

    [breakpoint('lg')]: {
        order: 'unset',
    },
}))

export const StyledInfoContainer = styled('div')<{ submitted?: boolean }>(
    ({ submitted = false }) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        borderRadius: `${metricsPN.spacing['M']}px`,
        rowGap: calcRem(metricsPN.spacing['XS']),

        [breakpoint('xxs')]: {
            display: 'grid',
            gridTemplateColumns: submitted ? '1fr' : '2fr 3fr',
            gridColumnStart: 1,
            gridColumnEnd: 6,
            columnGap: calcRem(metricsPN.spacing['L']),
        },

        [breakpoint('sm')]: {
            gridColumnStart: 2,
            gridColumnEnd: 5,
        },

        [breakpoint('lg')]: {
            display: 'flex',
            gridColumnStart: 'unset',
            gridColumnEnd: 'unset',
            columnGap: 'unset',
        },
    }),
)

export const StyledForm = styled.form({
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    marginBottom: calcRem(metricsPN.spacing['XS']),
})
export const StyledInputContainer = styled('div')({
    position: 'relative',
    width: '100%',
    marginBottom: calcRem(metricsPN.spacing['XS']),
})
export const StyledFloatingLabel = styled('label')({
    position: 'absolute',
    // (Input height (48) - label height (18)) / 2 (top and bottom spacing) = 15
    top: calcRem(15),
    left: calcRem(metricsPN.spacing['XS']),
    pointerEvents: 'none',
    ...perthNowNGNTextMixin.label.M.regular,
    color: colorsPN.text.primary,
})

export const StyledInput = styled('input')<StyledInputProps>(
    ({ hasError }) => ({
        ...perthNowNGNTextMixin.label['L'].regular,
        color: hasError ? colorsPN.text.error : colorsPN.text.primary,
        width: '100%',
        height: calcRem(48),
        padding: hasError
            ? calcRem(
                  metricsPN.spacing['L'],
                  metricsPN.spacing['2XL'],
                  metricsPN.spacing['XS'],
                  metricsPN.spacing['XS'],
              )
            : calcRem(
                  metricsPN.spacing['L'],
                  metricsPN.spacing['XS'],
                  metricsPN.spacing['XS'],
              ),
        border: `1px solid ${
            hasError ? colorsPN.stroke.error : colorsPN.stroke.weak
        }`,
        borderRadius: calcRem(metricsPN.radius['S']),
        '&:focus': {
            outline: 'none',
            border: `2px solid ${
                hasError ? colorsPN.stroke.error : colorsPN.stroke.strong
            }`,
            // -1 for border size change adjustment
            padding: calcRem(
                metricsPN.spacing['M'],
                metricsPN.spacing['XS'],
                metricsPN.spacing['2XS'],
                metricsPN.spacing['XS'] - 1,
            ),
        },
        '&:focus, &:not(:placeholder-shown)': {
            ...perthNowNGNTextMixin.label['M'].regular,
        },
        '&:focus + label, &:not(:placeholder-shown) + label': {
            top: calcRem(metricsPN.spacing['2XS']),
            left: calcRem(metricsPN.spacing['XS']),
            ...perthNowNGNTextMixin.label.XS.regular,
            color: hasError ? colorsPN.text.error : colorsPN.text.secondary,
        },
        '&::placeholder': {
            color: 'transparent',
        },
    }),
)
export const StyledErrorMessage = styled('div')({
    ...perthNowNGNTextMixin.label.S.regular,
    color: colorsPN.text.error,
    marginTop: calcRem(metricsPN.spacing['2XS']),
    whiteSpace: 'nowrap',
})

export const StyledErrorIcon = styled.span({
    position: 'absolute',
    right: calcRem(metricsPN.spacing['XS']),
    top: calcRem(metricsPN.spacing['L']),
    transform: 'translateY(-50%)',
    '& svg path': {
        stroke: colorsPN.fill.error.strong,
    },
})

export const StyledErrorSVG = styled(IconAlertCircleTN)({
    verticalAlign: 'middle',
})

export const StyledLegalInfo = styled('span')(() => ({
    ...perthNowNGNTextMixin.body.S.regular,
    color: colorsPN.text.primary,
    display: 'block',
    width: '100%',
    whiteSpace: 'normal',
}))

export const StyledLegalLink = styled(WebLink)(() => ({
    ...perthNowNGNTextMixin.label.S.medium,
    color: colorsPN.text.brand,
    textDecoration: 'unset',

    '&:hover': {
        textDecoration: 'underline',
        outline: 'none',
    },

    '&:active': {
        color: colorsPN.text.pressed,
    },

    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['4XS']),
    },
}))

export const StyledInfoLink = styled(StyledLegalLink)({
    ...perthNowNGNTextMixin.label.M.medium,
})

export const StyledBannerWrapper = styled('div')<{
    marginTop?: number
    marginBottom?: number
}>(({ marginBottom = 0, marginTop = 0 }) => ({
    marginTop: marginTop,
    marginBottom: marginBottom,
}))
