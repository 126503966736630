import React, { useContext } from 'react'
import { useOriginUrl } from '../../warden'
import { useLocation } from 'react-router'
import {
    AppState,
    AuthenticationState,
    ConfigurationContext,
} from '@news-mono/web-common'
import { useAuthActions } from '../../authentication/AuthenticationProvider'
import { useSelector } from 'react-redux'
import {
    EmailVerifiedComponentProps,
    GetEmailVerifiedStateComponent,
    consumeQueryParamsAndReturn,
    onContinueReadingClick,
    onReturnHomeClick,
} from '../../warden/email-verified'

export const EmailVerified = () => {
    const originUrl = useOriginUrl()
    const config = useContext(ConfigurationContext)
    const homepageUrl = config.publicUrl

    // Brings user to the login page
    const { onLoginClick } = useAuthActions()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const isLoggedIn = authentication.isLoggedIn
    const componentProps: EmailVerifiedComponentProps = {
        onContinueReading: onContinueReadingClick(originUrl, homepageUrl),
        onReturnHome: onReturnHomeClick(homepageUrl),
        onReturnToLogin: () => onLoginClick('email-verified'),
        originUrl: originUrl,
        isLoggedIn: isLoggedIn,
    }

    const Component = consumeQueryParamsAndReturn(queryParams, {
        AlreadyVerified: (props) =>
            GetEmailVerifiedStateComponent({
                state: 'already_verified',
                props,
            }),
        Invalid: (props) =>
            GetEmailVerifiedStateComponent({ state: 'invalid', props }),
        Verified: VerifiedStateComponent,
    })

    return Component(componentProps)
}

const VerifiedStateComponent = (props: EmailVerifiedComponentProps) =>
    GetEmailVerifiedStateComponent({
        state: 'verified',
        props: {
            ...props,
            showThankYouMessage: false,
            originUrlValues: {
                showButton: true,
                text: `You can now leave comments on the article and access our exclusive features in the future.`,
                buttonText: `Continue reading`,
            },
            noOriginUrlValues: {
                showButton: true,
                showLoginButton: true,
                text: (
                    <>
                        You can now log in to leave comments on articles or
                        return <a href="/">home</a>.
                    </>
                ),
                buttonText: `Return to Home`,
            },
        },
    })
