import { metrics, PerthNowSection } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getPromoLayout } from '../../common/promoLayout'
import { perthNowSectionOverrider } from '../../common/sectionTheme'

export const getCricketPageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    topic: Topic,
): PageType<PerthNowSection> => ({
    kind: 'page',
    heading: 'Cricket',
    pageType: 'topic',
    pageMeta: {
        description: 'Cricket breaking news and updates from PerthNow',
    },
    adTargeting: getAdTargeting('home', 'sport', topic),
    section: 'sport',
    compositions: [
        layout.composition({
            type: 'zeus',
            props: {},
            contentAreas: {
                main: [
                    layout.component({
                        type: 'lima',
                        props: {
                            fixedRatios: ['16:9'],
                            initialColumns: 1,
                            finalColumns: 1,
                            hideByline: true,
                            verticalSpacing: 'sm',
                            cardLayout: [['teaserLargeTitle']],

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/cricket'],
                                includeSubTopics: true,
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                paging: {
                                    page: 1,
                                    pageOffset: 0,
                                    pageSize: 1,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'milo',
                        props: {
                            showLargeCard: false,
                            verticalSpacing: 'md',
                            teaserMode: 'visible',

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/cricket'],
                                includeSubTopics: true,
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                paging: {
                                    page: 1,
                                    pageOffset: 1,
                                    pageSize: 4,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            hiddenUntilLoaded: true,
                            noticePosition: 'below-center',
                            padding: [
                                metrics.perthnow.margins.sm,
                                0,
                                metrics.perthnow.margins.sm,
                                0,
                            ],
                            slot: {
                                id: 'mobile-mrec-one',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    layout.component({
                        type: 'lima',
                        props: {
                            fixedRatios: ['4:3', '16:9'],
                            finalColumns: 4,
                            fontScale: 0.75,
                            hideByline: true,
                            verticalSpacing: 'md',
                            remainingCardLayout: 'teaserSmallTitle',

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/cricket'],
                                includeSubTopics: true,
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                paging: {
                                    page: 1,
                                    pageOffset: 5,
                                    pageSize: 4,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'milo',
                        props: {
                            showLargeCard: true,
                            largeCard: {
                                largeCardHasBackground: true,
                            },

                            hideByline: true,
                            verticalSpacing: 'md',
                            teaserMode: 'visible',

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/cricket'],
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                paging: {
                                    page: 1,
                                    pageOffset: 10,
                                    pageSize: 3,
                                },
                            },
                        },
                    }),
                ],
                sidebar: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'sidebar-top',
                                size: 'desktopMrecHalfPage',
                            },
                            adType: 'inline',
                        },
                    }),
                    getPromoLayout('sport'),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'juliet',
                            props: {
                                cardType: {
                                    type: 'landscape',
                                    format: 'landscape-common',
                                },
                                removeHorizontalGutters: true,
                                verticalSpacing: 'md',
                                sectionHeader: {
                                    heading: 'Latest Sport News',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport'],
                                    // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 7,
                                    },
                                },
                                kickerMode: 'hidden',
                            },
                        }),
                    ]),
                ],
            },
        }),
        layout.composition({
            type: 'thor',
            props: {
                hasBackgroundFill: false,
                horizontalGutters: true,
                verticalGutters: false,
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            hiddenUntilLoaded: true,
                            noticePosition: 'below-center',
                            padding: [
                                metrics.perthnow.margins.sm,
                                0,
                                metrics.perthnow.margins.sm,
                                0,
                            ],
                            slot: {
                                id: 'mobile-mrec-two',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    perthNowSectionOverrider('sport', [
                        layout.nestedComposition({
                            type: 'cerberus',
                            props: {
                                verticalSpacing: 'md',
                                stretchColumns: true,
                            },
                            contentAreas: {
                                left: [
                                    layout.component({
                                        type: 'oscar',
                                        props: {
                                            sectionHeader: {
                                                heading: 'Big Bash League',
                                                headingUrl:
                                                    '/sport/big-bash-league',
                                            },
                                            collectionSection: 'sport',
                                            disableImageLazyLoad: true,
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: [
                                                    'sport/big-bash-league',
                                                ],
                                                includeSubTopics: true,
                                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                                publicationKind: 'article',
                                                paging: {
                                                    page: 1,
                                                    pageSize: 6,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                middle: [
                                    layout.component({
                                        type: 'oscar',
                                        props: {
                                            sectionHeader: {
                                                heading: 'Perth Scorchers',
                                                headingUrl:
                                                    '/sport/perth-scorchers',
                                            },
                                            collectionSection: 'sport',
                                            disableImageLazyLoad: true,
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: [
                                                    'sport/perth-scorchers',
                                                ],
                                                includeSubTopics: true,
                                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                                publicationKind: 'article',
                                                paging: {
                                                    page: 1,
                                                    pageSize: 6,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                right: [
                                    layout.component({
                                        type: 'oscar',
                                        props: {
                                            sectionHeader: {
                                                heading: 'The Ashes',
                                                headingUrl: '/sport/the-ashes',
                                            },
                                            collectionSection: 'sport',
                                            disableImageLazyLoad: true,
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/the-ashes'],
                                                includeSubTopics: true,
                                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                                publicationKind: 'article',
                                                paging: {
                                                    page: 1,
                                                    pageSize: 6,
                                                },
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                    ]),
                    getPromoLayout('sport', { mobileOnly: true }),
                    layout.component({
                        type: 'taboola',
                        props: {
                            taboolaContainerId:
                                'taboola-below-category-thumbnails',
                            locationType: 'category',
                        },
                    }),
                ],
            },
        }),
    ],
})
