import {
    CardBreakpointRatios,
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
    FixedRatio,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { TeaserMode } from '../../cards/CardText/CardTeaser'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import { invertMaybeLoadedItems } from '../helpers/loading'
import { HeaderSectionTagType, PNArticleCard } from '../../nextgen-news'
import { StyledCardStack, StyledMain, StyledStella } from './PNStella.styled'

export interface LargeCard {
    largeCardPosition?: 'left' | 'right' | undefined
    largeCardType?: 'portrait' | 'landscape' | undefined
}

export interface PNStellaProps extends ResponsiveContainer {
    className?: string
    fixedRatios?: FixedRatio[] | CardBreakpointRatios
    teaserMode: TeaserMode
    onEvent: (event: CollectionEvent) => void
    disableImageLazyLoad?: boolean
    expectedCards: number
    items: MaybeLoaded<CardItem[]>
    canPlayVideoInline?: boolean
    showAuthor?: boolean
    headerSectionTag?: HeaderSectionTagType
}

export const PNStella: React.FC<PNStellaProps> = (props) => {
    const {
        className,
        onEvent,
        disableImageLazyLoad,
        fixedRatios,
        expectedCards,
        canPlayVideoInline,
        showAuthor,
        headerSectionTag,
    } = props
    const product = useProduct()

    const items = invertMaybeLoadedItems(props.items, expectedCards)
    // We only need five (2 on the left + 3 on the right)
    const numberSlicedItems = 2
    const numberTotalItems = 5
    const leftColumnItems = items.slice(0, numberSlicedItems)
    const rightColumnItems = items.slice(numberSlicedItems, numberTotalItems)

    /* One large and one small card */
    const twoCardsStack = () => (
        <StyledMain>
            {leftColumnItems.map((item, index) => (
                <CardSwitcher
                    onEvent={onEvent}
                    item={item}
                    cardContext={`pn-stella-${
                        index === 0 ? 'large' : 'stacked'
                    }`}
                    cardNumber={index + 1}
                    publicationCard={(publicationItem) => (
                        <PNArticleCard
                            fontScale="M"
                            headerFontOverride={
                                index === 0
                                    ? {
                                          mobile: 'M',
                                          tablet: 'XL',
                                          desktop: 'XL',
                                      }
                                    : undefined
                            }
                            cardType={index === 0 ? 'vertical' : 'horizontal'}
                            item={publicationItem}
                            onEvent={onEvent}
                            fixedRatio={canPlayVideoInline ? '16:9' : '4:3'}
                            disableImageLazyLoad={disableImageLazyLoad}
                            cardNumber={index + 1}
                            teaserMode="visible"
                            teaserFontScale="XL"
                            headerSectionTag={headerSectionTag}
                            showAuthor={showAuthor}
                            kickerMode={index === 0 ? 'top' : 'middle'}
                        />
                    )}
                />
            ))}
        </StyledMain>
    )

    /* Three Landscape Stacked cards */
    const threeCardsStack = () => {
        return (
            <StyledCardStack>
                {rightColumnItems.map((item, index) => (
                    <CardSwitcher
                        onEvent={onEvent}
                        key={index}
                        item={item}
                        cardContext="pn-stella-stacked"
                        cardNumber={numberSlicedItems + index + 1}
                        publicationCard={(publicationItem) => (
                            <PNArticleCard
                                cardType="horizontal"
                                item={publicationItem}
                                onEvent={onEvent}
                                disableImageLazyLoad={disableImageLazyLoad}
                                teaserMode={props.teaserMode}
                                fixedRatio={fixedRatios}
                                cardNumber={numberSlicedItems + index + 1}
                                fontScale={'M'}
                                showAuthor={showAuthor}
                                headerSectionTag={'h2'}
                                teaserFontScale={'XL'}
                                imageSizeOverride={{
                                    mobile: 'XS',
                                    tablet: 'M',
                                    desktop: 'M',
                                }}
                            />
                        )}
                    />
                ))}
            </StyledCardStack>
        )
    }

    return (
        <ImpressionAvailable
            loading={!props.items.loaded}
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                props.onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'Stella',
                        product,
                        props.onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledStella ref={ref} className={className} largeCardSpan={2}>
                    {twoCardsStack()}
                    {threeCardsStack()}
                </StyledStella>
            )}
        </ImpressionAvailable>
    )
}
