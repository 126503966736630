import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { PerthNowSection } from '@news-mono/component-library'
import {
    GetRouteAdTargeting,
    PageType,
    Store,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { makeMiloCollection } from '../../common/miloCollection'
import { getPromoLayout } from '../../common/promoLayout'
import { perthNowSectionOverrider } from '../../common/sectionTheme'
import { playOfTheDaySegment } from '../../home/home-layout'
import { makeSportOscarCollection } from '../common'

export const getSportsPageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
    topic: Topic,
): PageType<PerthNowSection> => ({
    kind: 'page',
    heading: 'Sport',
    pageType: 'topic',
    pageMeta: {
        description: 'Sport news and updates from PerthNow',
    },
    adTargeting: getAdTargeting('home', 'sport', topic),
    section: 'sport',
    compositions: [
        layout.composition({
            type: 'zeus',
            props: {},
            contentAreas: {
                main: [
                    layout.component({
                        type: 'portrait',
                        props: {
                            disableImageLazyLoad: true,
                            largeKicker: true,
                            mediaMode: 'edgeToEdge',
                            teaserMode: 'visible',
                            willPlayVideoInline: true,
                            fullWidthOnMobile: false,
                            kickerPosition: 'topleftinset',
                            hideByline: true,
                            hasPadding: true,
                            verticalSpacing: 'md',
                            fixedRatio: '16:9',
                            fontScale: 2,
                            isLarge: true,

                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'sport',
                                offset: 0,
                                pageSize: 1,
                            },
                        },
                    }),
                    layout.component({
                        type: 'lima',
                        props: {
                            fixedRatios: ['4:3'],
                            initialColumns: 1,
                            intermediateColumns: 2,
                            finalColumns: 4,
                            hideByline: true,
                            verticalSpacing: 'md',
                            remainingCardLayout: 'teaserSmallTitle',

                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'sport',
                                offset: 1,
                                pageSize: 4,
                            },
                        },
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'mobile-mrec-one',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    layout.component({
                        type: 'lima',
                        props: {
                            fixedRatios: {
                                default: ['161:229', '316:421'],
                                sm: '4:3',
                            },
                            initialColumns: 1,
                            finalColumns: 3,
                            hideByline: true,
                            verticalSpacing: 'md',
                            remainingCardLayout: 'tallPortrait',

                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'sport',
                                offset: 5,
                                pageSize: 3,
                            },
                        },
                    }),
                    layout.component({
                        type: 'milo',
                        props: {
                            verticalSpacing: 'md',
                            showLargeCard: true,
                            largeCard: {
                                largeCardHasBackground: true,
                            },
                            teaserMode: 'visible',

                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'sport',
                                offset: 8,
                                pageSize: 3,
                            },
                        },
                    }),
                    ...playOfTheDaySegment({
                        mobileOnly: true,
                        featureOverride: 'perthnow-play-of-the-day',
                        themeOverride: isFeatureEnabled(
                            toFeatureState(store.getState().toggles),
                            'perthnow-streamer-screamer-play-of-the-day',
                        )
                            ? 'streamer-screamer'
                            : undefined,
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'mobile-mrec-two',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    makeMiloCollection(
                        'AFL',
                        '/sport/afl',
                        ['sport/afl'],
                        'sport',
                        [
                            {
                                text: 'West Coast',
                                link: '/sport/west-coast-eagles',
                            },
                            {
                                text: 'Fremantle',
                                link: '/sport/fremantle-dockers',
                            },
                            { text: 'WAFL', link: '/sport/wafl' },
                            { text: 'AFLW', link: '/sport/aflw' },
                        ],
                    ),
                    makeMiloCollection(
                        'Cricket',
                        '/sport/cricket',
                        ['sport/cricket'],
                        'sport',
                        [
                            {
                                text: 'Big Bash',
                                link: '/sport/big-bash-league',
                            },
                            {
                                text: 'Scorchers',
                                link: '/sport/perth-scorchers',
                            },
                        ],
                    ),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'mobile-mrec-three',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    makeMiloCollection(
                        'Soccer',
                        '/sport/soccer',
                        ['sport/soccer'],
                        'sport',
                        [
                            { text: 'A-League', link: '/sport/a-league' },
                            { text: 'Glory', link: '/sport/perth-glory' },
                            {
                                text: 'Premier League',
                                link: '/sport/english-premier-league',
                            },
                        ],
                    ),
                    makeMiloCollection(
                        'Basketball',
                        '/sport/basketball',
                        ['sport/basketball'],
                        'sport',
                        [
                            {
                                text: 'Wildcats',
                                link: '/sport/perth-wildcats',
                            },
                        ],
                    ),
                    makeMiloCollection(
                        'Tennis',
                        '/sport/tennis',
                        ['sport/tennis'],
                        'sport',
                        [
                            {
                                text: 'Australian Open',
                                link: '/sport/australian-open',
                            },
                        ],
                    ),
                    makeMiloCollection(
                        'NRL',
                        '/sport/rugby-league',
                        ['sport/rugby-league'],
                        'sport',
                        [
                            {
                                text: 'Origin',
                                link: '/sport/state-of-origin',
                            },
                        ],
                    ),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'mobile-mrec-four',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    makeMiloCollection(
                        'Rugby',
                        '/sport/rugby',
                        ['sport/rugby-union'],
                        'sport',
                        [
                            { text: 'Super', link: '/sport/rugby-union' },
                            { text: 'Force', link: '/sport/western-force' },
                            { text: 'Wallabies', link: '/sport/wallabies' },
                        ],
                    ),
                    makeMiloCollection(
                        'Motorsport',
                        '/sport/motorsport',
                        ['sport/motorsport'],
                        'sport',
                        [
                            { text: 'F1', link: '/sport/f1' },
                            { text: 'V8', link: '/sport/v8' },
                            { text: 'MotoGP', link: '/sport/moto-gp' },
                            {
                                text: 'Superbikes',
                                link: '/sport/superbikes',
                            },
                            { text: 'Indycar', link: '/sport/indycar' },
                            { text: 'Nascar', link: '/sport/nascar' },
                        ],
                    ),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },
                                verticalSpacing: 'sm',
                                teaserMode: 'visible',

                                sectionHeader: {
                                    heading: 'Racing',
                                    headingUrl: 'sport/horse-racing',
                                    navLinks: [
                                        {
                                            text: 'International Races',
                                            link: '/sport/international-races',
                                        },
                                        {
                                            text: 'WA',
                                            link: '/sport/wa-races',
                                        },
                                        {
                                            text: 'VIC',
                                            link: '/sport/vic-races',
                                        },
                                        {
                                            text: 'QLD',
                                            link: '/sport/qld-races',
                                        },
                                        {
                                            text: 'NSW',
                                            link: '/sport/nsw-races',
                                        },
                                        {
                                            text: 'SA',
                                            link: '/sport/sa-races',
                                        },
                                    ],
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/horse-racing'],
                                    includeSubTopics: true,
                                    paging: {
                                        page: 1,
                                        pageOffset: 0,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                    ]),
                ],
                sidebar: [
                    ...playOfTheDaySegment({
                        featureOverride: 'perthnow-play-of-the-day',
                        themeOverride: isFeatureEnabled(
                            toFeatureState(store.getState().toggles),
                            'perthnow-streamer-screamer-play-of-the-day',
                        )
                            ? 'streamer-screamer'
                            : undefined,
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            padding: [0, 0, 13, 0],
                            hiddenUntilLoaded: true,
                            slot: {
                                id: 'sidebar-top',
                                size: 'desktopMrecHalfPage',
                            },
                            adType: 'inline',
                        },
                    }),
                    getPromoLayout('sport'),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'juliet',
                            props: {
                                cardType: {
                                    type: 'landscape',
                                    format: 'landscape-common',
                                },
                                removeHorizontalGutters: true,
                                verticalSpacing: 'sm',
                                kickerMode: 'hidden',
                                sectionHeader: {
                                    heading: 'Latest Sport News',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport'],
                                    paging: {
                                        page: 1,
                                        pageSize: 18,
                                    },
                                },
                            },
                        }),
                    ]),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: false,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'sidebar-two',
                                size: 'desktopMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    layout.component({
                        type: 'juliet',
                        props: {
                            cardType: {
                                type: 'landscape',
                                format: 'landscape-common',
                            },
                            removeHorizontalGutters: true,
                            verticalSpacing: 'sm',
                            kickerMode: 'hidden',
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport'],
                                paging: {
                                    page: 1,
                                    pageOffset: 18,
                                    pageSize: 18,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: false,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'sidebar-three',
                                size: 'desktopMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    layout.component({
                        type: 'juliet',
                        props: {
                            cardType: {
                                type: 'landscape',
                                format: 'landscape-common',
                            },
                            removeHorizontalGutters: true,
                            verticalSpacing: 'md',
                            kickerMode: 'hidden',
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport'],
                                paging: {
                                    page: 1,
                                    pageOffset: 36,
                                    pageSize: 18,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'sidebar-four',
                                size: 'desktopMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    layout.component({
                        type: 'juliet',
                        props: {
                            cardType: {
                                type: 'landscape',
                                format: 'landscape-common',
                            },
                            removeHorizontalGutters: true,
                            verticalSpacing: 'md',
                            kickerMode: 'hidden',
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport'],
                                paging: {
                                    page: 1,
                                    pageOffset: 54,
                                    pageSize: 6,
                                },
                            },
                        },
                    }),
                ],
            },
        }),
        layout.composition({
            type: 'thor',
            props: {
                hasBackgroundFill: false,
                horizontalGutters: true,
                verticalGutters: false,
            },
            contentAreas: {
                main: [
                    layout.nestedComposition({
                        type: 'cerberus',
                        props: {
                            verticalSpacing: 'md',
                            stretchColumns: true,
                        },
                        contentAreas: {
                            left: [
                                makeSportOscarCollection(
                                    'Surfing',
                                    '/sport/surfing',
                                    'sport/surfing',
                                ),
                            ],
                            middle: [
                                makeSportOscarCollection(
                                    'MMA',
                                    '/sport/mixed-martial-arts',
                                    'sport/mixed-martial-arts',
                                ),
                            ],
                            right: [
                                makeSportOscarCollection(
                                    'Golf',
                                    '/sport/golf',
                                    'sport/golf',
                                ),
                            ],
                        },
                    }),
                    layout.nestedComposition({
                        type: 'cerberus',
                        props: {
                            verticalSpacing: 'xl',
                            stretchColumns: true,
                        },
                        contentAreas: {
                            left: [
                                makeSportOscarCollection(
                                    'Netball',
                                    '/sport/netball',
                                    'sport/netball',
                                ),
                            ],
                            middle: [
                                makeSportOscarCollection(
                                    'Cycling',
                                    '/sport/cycling',
                                    'sport/cycling',
                                ),
                            ],
                            right: [
                                makeSportOscarCollection(
                                    'Other Sports',
                                    '/sport/other-sports',
                                    'sport/other-sports',
                                ),
                            ],
                        },
                    }),
                    getPromoLayout('sport', { mobileOnly: true }),
                    layout.component({
                        type: 'taboola',
                        props: {
                            taboolaContainerId:
                                'taboola-below-category-thumbnails',
                            locationType: 'category',
                        },
                    }),
                ],
            },
        }),
    ],
})
