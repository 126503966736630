import React, { FC, Fragment, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { PNLiveEventPost } from './PNLiveEventPost'
import { StyledPNLiveEventTimeline } from './PNLiveEventTimeline.styled'
import { AdUnitWrapper } from '../../../../advertising'
import { useIsComponentOnScreen } from '../../../../publication/LiveEvent/helpers'
import { LiveEventTimelineProps } from '../../../../publication/LiveEvent/Components'
import { PNLiveEventPinnedPosts } from './PNLiveEventPinnedPosts'

export const PNLiveEventTimeline: FC<LiveEventTimelineProps> = ({
    posts,
    adUnits,
    eventTitle,
    log,
    triggerLazyLoad,
    onEvent,
    pinnedPosts,
    adState,
    deepLinkedPostID,
    entryType,
}) => {
    const { search } = useLocation()
    const liveEventPostsRef = useRef<HTMLDivElement>(null)
    const { componentRef } = useIsComponentOnScreen('0px', triggerLazyLoad)

    const hasPinnedPosts = pinnedPosts.length > 0
    const isPaginated = new URLSearchParams(search).get('page')

    // Scroll to the paginated posts when the user clicks on the pagination
    useEffect(() => {
        if (isPaginated && liveEventPostsRef.current) {
            liveEventPostsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }, [isPaginated])

    return (
        <StyledPNLiveEventTimeline ref={liveEventPostsRef}>
            {hasPinnedPosts && (
                <PNLiveEventPinnedPosts
                    pinned={pinnedPosts}
                    eventTitle={eventTitle}
                    log={log}
                    adUnitPath={adState.targeting.adUnitPath}
                    onEvent={onEvent}
                />
            )}
            {posts.map((post, index) => (
                <Fragment key={post.id}>
                    <PNLiveEventPost
                        post={post}
                        log={log}
                        eventTitle={eventTitle}
                        adUnitPath={adState.targeting.adUnitPath}
                        lazyLoadRef={
                            index === posts.length - 2
                                ? componentRef
                                : undefined
                        }
                        onEvent={onEvent}
                        isActivePost={deepLinkedPostID === post.id}
                        entryType={entryType}
                    />
                    {index in adUnits && (
                        // Add adunit if expected here.
                        <AdUnitWrapper
                            noticePosition="below-center"
                            padding={[4, 0, 4, 0]}
                            adState={adState}
                            unitId={adUnits[index].id}
                            onEvent={onEvent}
                            adType={'inline'}
                        />
                    )}
                </Fragment>
            ))}
        </StyledPNLiveEventTimeline>
    )
}
