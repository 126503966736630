import { PerthNowSection } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType, Topic } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { getPromoLayout } from '../common/promoLayout'
import { getSidebarCollection } from '../lib/get-sidebar-collection'
import { sidebarCollectionWANewsNoHeader } from '../common/sidebarCollection'
import { perthNowSectionOverrider } from '../common/sectionTheme'
import { makeMiloCollection } from '../common/miloCollection'

export const getNewsPageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    topic: Topic,
): PageType<PerthNowSection> => {
    return {
        kind: 'page',
        heading: topic.title,
        pageType: 'topic',
        pageMeta: {
            title: topic.title,
            description: topic.seoDescription,
        },
        socialMeta: {
            title: topic.seoTitle,
            description: topic.seoDescription,
        },
        adTargeting: getAdTargeting('home', topic.id, topic),
        section: 'news',
        compositions: [
            layout.composition({
                type: 'zeus',
                props: {},
                contentAreas: {
                    main: [
                        perthNowSectionOverrider('news', [
                            layout.component({
                                type: 'portrait',
                                props: {
                                    disableImageLazyLoad: true,
                                    largeKicker: true,
                                    mediaMode: 'edgeToEdge',
                                    teaserMode: 'visible',
                                    willPlayVideoInline: true,
                                    fullWidthOnMobile: false,
                                    kickerPosition: 'topleftinset',
                                    hideByline: true,
                                    hasPadding: true,
                                    verticalSpacing: 'md',
                                    fixedRatio: '16:9',
                                    fontScale: 2,
                                    isLarge: true,

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    hiddenUntilLoaded: true,
                                    padding: [0, 0, 13, 0],
                                    slot: {
                                        id: 'mobile-mrec-one',
                                        size: 'mobileMrec',
                                    },
                                    adType: 'inline',
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    fixedRatios: {
                                        default: ['161:229', '316:421'],
                                        sm: '4:3',
                                    },
                                    initialColumns: 1,
                                    finalColumns: 3,
                                    hideByline: true,
                                    verticalSpacing: 'md',
                                    remainingCardLayout: 'tallPortrait',

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'news',
                                        offset: 1,
                                        pageSize: 3,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'milo',
                                props: {
                                    verticalSpacing: 'md',
                                    showLargeCard: true,
                                    largeCard: {
                                        largeCardHasBackground: true,
                                    },
                                    teaserMode: 'visible',

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'news',
                                        offset: 4,
                                        pageSize: 3,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'section-header',
                                props: {
                                    heading: 'WA News',
                                    hasBottomMargin: true,
                                    headingUrl: '/news/wa',
                                },
                            }),
                            layout.component({
                                type: 'portrait',
                                props: {
                                    disableImageLazyLoad: true,
                                    largeKicker: true,
                                    mediaMode: 'edgeToEdge',
                                    teaserMode: 'visible',
                                    willPlayVideoInline: true,
                                    fullWidthOnMobile: false,
                                    kickerPosition: 'topleftinset',
                                    hideByline: true,
                                    hasPadding: true,
                                    verticalSpacing: 'md',
                                    fixedRatio: '16:9',
                                    fontScale: 2,
                                    isLarge: true,

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'wa-news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    hiddenUntilLoaded: true,
                                    padding: [0, 0, 13, 0],
                                    slot: {
                                        id: 'mobile-mrec-one',
                                        size: 'mobileMrec',
                                    },
                                    adType: 'inline',
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    fixedRatios: {
                                        default: ['161:229', '316:421'],
                                        sm: '4:3',
                                    },
                                    initialColumns: 1,
                                    finalColumns: 3,
                                    hideByline: true,
                                    verticalSpacing: 'md',
                                    remainingCardLayout: 'tallPortrait',

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'wa-news',
                                        offset: 1,
                                        pageSize: 3,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'milo',
                                props: {
                                    verticalSpacing: 'md',
                                    showLargeCard: true,
                                    largeCard: {
                                        largeCardHasBackground: true,
                                    },
                                    teaserMode: 'visible',

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'wa-news',
                                        offset: 4,
                                        pageSize: 3,
                                    },
                                },
                            }),
                            makeMiloCollection(
                                'National',
                                '/news/australia',
                                ['news/australia'],
                                'news',
                            ),
                            makeMiloCollection(
                                'World',
                                '/news/world',
                                ['news/world'],
                                'news',
                            ),
                            makeMiloCollection(
                                'Technology',
                                '/technology',
                                ['technology'],
                                'news',
                            ),
                            makeMiloCollection(
                                'Opinion',
                                '/opinion',
                                ['opinion'],
                                'news',
                            ),
                            makeMiloCollection(
                                'Weather',
                                '/news/weather',
                                ['news/weather'],
                                'news',
                            ),
                        ]),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-top',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        getPromoLayout(
                            topic.parent ? topic.parent.id : topic.id,
                        ),
                        getSidebarCollection({
                            section: 'sport',
                            currentSection: 'news',
                            heading: 'Latest Sport News',
                            topics: ['sport'],
                            pageSize: 7,
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-middle',
                                    size: 'desktopMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                        sidebarCollectionWANewsNoHeader({
                            pageSize: 8,
                            offset: 12,
                        }),
                    ],
                },
            }),
        ],
    }
}
