import React, { useCallback } from 'react'
import {
    AllEvents,
    DataLayerEventName,
    NavEvent,
    NavEventArgs,
} from '@news-mono/web-common'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'
import {
    StyledModalWrapper,
    StyledModal,
    StyledHeaderLink,
    StyledHeader,
    StyledSubNavContainer,
} from './SecondaryMenuContainer.styled'
import { SecondaryNav } from './SecondaryNav'
import {
    PerthNowSection,
    RecaptchaProvider,
} from '@news-mono/component-library'
import { LatestStories } from './LatestStories'
import { SubscriberLinks } from './SubscriberLinks'
import { PNSubNavLinkNGN } from '../../PNNavigation'
import { NewsletterFormInputHook } from '../../../helpers/newsletterSignUp'

export interface Main {
    isOpen: boolean
}
export interface SecondaryMenuContainerProps {
    ref?: React.RefObject<HTMLDivElement>
    focusRef: React.RefObject<HTMLAnchorElement>
    section: PerthNowSection
    parent: {
        name: string
        link: string
        section: PerthNowSection
        subNavLinks?: PNSubNavLinkNGN[]
        forceInternalSSR?: boolean
    }
    subscribeLink: string
    findMyPaperLink: string
    subNavLinks: PNSubNavLinkNGN[]
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    onEvent: (event: AllEvents) => void
    latestStories: ListPublicationV4DTO[]
    formHook: NewsletterFormInputHook
    recaptureSiteKey: string
    handleTouchStart: () => void
    link: string
    pos: number
    isSnapshot?: boolean // This is a flag to determine if the component is being rendered in a snapshot
    tabIndex: number
}

export const SecondaryMenuContainer: React.FC<SecondaryMenuContainerProps> = ({
    focusRef,
    subNavLinks,
    section,
    subscribeLink,
    findMyPaperLink,
    isOpen,
    setIsOpen,
    parent,
    onEvent,
    latestStories,
    formHook,
    recaptureSiteKey,
    handleTouchStart,
    link,
    isSnapshot = false,
    pos,
    tabIndex,
}) => {
    const { name } = parent

    const handleClick = useCallback(
        (args: NavEventArgs) => {
            onEvent({
                type: DataLayerEventName.navClicked,
                originator: 'PNMainNavigationItem',
                payload: {
                    navName: 'Default.PerthNow.MainNavigation',
                    navLocation: 'Header',
                    navPos: args.navPos,
                    navText: args.navText,
                    navLink: args.navLink,
                    navPath: args.navText,
                },
            })

            if (!subNavLinks.length) return
        },
        [onEvent, subNavLinks],
    )

    return (
        <StyledModalWrapper isOpen={isOpen} isSnapshot={isSnapshot}>
            <StyledModal aria-label="Perth Navigation Section">
                <StyledHeaderLink
                    innerRef={focusRef}
                    to={link}
                    tabIndex={tabIndex}
                    onClick={() => {
                        handleClick({
                            navPos: pos,
                            navText: name,
                            navLink: link,
                        })
                        setIsOpen(false)
                    }}
                    onTouchStart={handleTouchStart}
                >
                    <StyledHeader>{name}</StyledHeader>
                </StyledHeaderLink>
                <StyledSubNavContainer aria-label="Main Navigation">
                    <SecondaryNav
                        subNavLinks={subNavLinks}
                        parent={parent}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        onEvent={onEvent}
                        aria-label="submenu"
                        tabIndex={tabIndex}
                    />
                    <LatestStories
                        parent={parent}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        onEvent={onEvent}
                        latestStories={latestStories}
                        tabIndex={tabIndex}
                    />
                    <RecaptchaProvider siteKey={recaptureSiteKey}>
                        <SubscriberLinks
                            isOpen={isOpen}
                            subscribeLink={subscribeLink}
                            findMyPaperLink={findMyPaperLink}
                            onEvent={onEvent}
                            setIsOpen={setIsOpen}
                            formHook={formHook}
                            tabIndex={tabIndex}
                        />
                    </RecaptchaProvider>
                </StyledSubNavContainer>
            </StyledModal>
        </StyledModalWrapper>
    )
}
