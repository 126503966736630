import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { PerthNowSection } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    GetRouteAdTargeting,
    PageType,
    RouteResolution,
    Store,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getPromoLayout } from '../../common/promoLayout'
import { perthNowSectionOverrider } from '../../common/sectionTheme'
import { playOfTheDaySegment } from '../../home/home-layout'

const socialImage = require('../assets/Flagmantle Social 1200x628_V2.jpg')

export const getSocialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '628',
    },
    {
        property: 'og:image:width',
        content: '1220',
    },
]

export const getFlagmantlePageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
    resolution: RouteResolution,
    topic: Topic,
): PageType<PerthNowSection> => {
    const title = getResolvedTopicPageMeta(resolution)?.seoTitle || 'Flagmantle'
    const description =
        getResolvedTopicPageMeta(resolution)?.seoDescription ||
        'Sport news and updates from PerthNow'

    return {
        kind: 'page',
        heading: 'Flagmantle',
        pageType: 'topic',
        socialMeta: {
            title,
            description,
        },
        pageMeta: {
            title,
            description,
            meta: getSocialImageMeta,
        },
        adTargeting: getAdTargeting('home', 'sport', topic),
        section: 'sport',
        compositions: [
            layout.composition({
                type: 'zeus',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'juliet',
                            props: {
                                cardType: 'LandscapeWide',
                                showLargeCard: true,
                                verticalSpacing: 'sm',
                                disableImageLazyLoad: true,
                                hideByline: true,
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'flagmantle',
                                    pageSize: 5,
                                    offset: 0,
                                },
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                hiddenUntilLoaded: true,
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'mobile-mrec-one',
                                    size: 'mobileMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'juliet',
                            props: {
                                cardType: 'LandscapeWide',
                                showLargeCard: false,
                                verticalSpacing: 'sm',
                                disableImageLazyLoad: true,
                                hideByline: true,
                                sectionHeader: {
                                    heading: 'Latest News',
                                },
                                loadMoreEnabled: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    paging: {
                                        page: 1,
                                        pageSize: 10,
                                    },
                                    topics: ['sport/fremantle-dockers'],
                                },
                            },
                        }),
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                            },
                        }),
                    ],
                    sidebar: [
                        ...playOfTheDaySegment({
                            featureOverride: 'perthnow-play-of-the-day',
                            themeOverride: isFeatureEnabled(
                                toFeatureState(store.getState().toggles),
                                'perthnow-streamer-screamer-play-of-the-day',
                            )
                                ? 'streamer-screamer'
                                : undefined,
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                hiddenUntilLoaded: true,
                                slot: {
                                    id: 'sidebar-two',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        getPromoLayout('sport'),
                        perthNowSectionOverrider('sport', [
                            layout.component({
                                type: 'juliet',
                                props: {
                                    cardType: {
                                        type: 'landscape',
                                        format: 'landscape-common',
                                    },
                                    removeHorizontalGutters: true,
                                    verticalSpacing: 'md',
                                    sectionHeader: {
                                        heading: 'Latest Sport News',
                                    },
                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: ['sport'],
                                        // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                        publicationKind: 'article',
                                        paging: {
                                            page: 1,
                                            pageSize: 7,
                                        },
                                    },
                                    kickerMode: 'hidden',
                                },
                            }),
                        ]),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    hasBackgroundFill: false,
                    horizontalGutters: true,
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [getPromoLayout('sport', { mobileOnly: true })],
                },
            }),
        ],
    }
}
