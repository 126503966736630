import { PerthNowNavItem } from '@news-mono/component-library'
import { NavItem } from '@news-mono/web-common'
import { RoutablePerthNowSection } from '@west-australian-newspapers/publication-types'
import { navigationOriginalTaxonomyNGN } from '../../BasePerthNow/BaseComponent/Navigation/navigation-items'

export const getPerthNowSectionNav = (): Record<
    RoutablePerthNowSection,
    PerthNowNavItem
> => {
    const navSectionRecord = {} as Record<
        RoutablePerthNowSection,
        PerthNowNavItem
    >
    navigationOriginalTaxonomyNGN.forEach((navItem) => {
        const navSection = navItem.name
            .toLowerCase()
            .split(' ')
            .join('-') as RoutablePerthNowSection

        navSectionRecord[navSection] = navItem
    })

    return navSectionRecord
}

export const getPerthNowSubTopicNavItems = (): Record<
    RoutablePerthNowSection,
    NavItem[]
> => {
    const navSectionRecord = {} as Record<RoutablePerthNowSection, NavItem[]>
    navigationOriginalTaxonomyNGN.forEach((navItem) => {
        const navSection = navItem.name
            .toLowerCase()
            .split(' ')
            .join('-') as RoutablePerthNowSection

        navSectionRecord[navSection] = navItem.subNavLinks
            ? navItem.subNavLinks
            : []
    })
    return navSectionRecord
}
