import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { Store, TogglesReduxState } from '@news-mono/web-common'

/**
 *
 * @param toggles TogglesReduxState
 * @param toggleName Child component toggle name
 * @returns boolean
 */
export function isNextGenPNEnabled(
    toggles: TogglesReduxState,
    toggleName: string,
) {
    const masterToggle = 'perthnow-ngn-redesign-master'

    return (
        isFeatureEnabled(toFeatureState(toggles), masterToggle) &&
        !isFeatureEnabled(toFeatureState(toggles), toggleName)
    )
}

export const isNGNTopicPageRedesignEnabled = (store: Store) => {
    return isNextGenPNEnabled(
        store.getState().toggles,
        'perthnow-ngn-topic-disabled',
    )
}
