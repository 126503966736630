import styled from '@emotion/styled'
import { WebNavLink } from '@news-mono/web-common'
import { calcRem, breakpointMax } from '@news-mono/component-library'
import { perthNowNGNTextMixin } from '../../../../mixins/TextMixin/TextMixin.theme'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'

export interface StyledListItemProps {
    isOpen: boolean
}

const getParentNavStyles = (isOpen: boolean) =>
    ({
        ...perthNowNGNTextMixin.label.L.medium,
        whiteSpace: 'nowrap',
        position: 'relative',
        textTransform: 'capitalize',
        height: '100%',
        alignContent: 'center',
        padding: calcRem(metricsPN.spacing['XS']),
        color: isOpen ? colorsPN.text.brand : colorsPN.text.primary,
        textDecoration: 'none',

        '&:hover': {
            color: colorsPN.text.brand,
        },
        '&:focus-visible': {
            outline: 'revert',
            borderRadius: `${metricsPN.radius['S']}px`,
        },
        '&.active': {
            textDecoration: 'underline',
            textUnderlineOffset: calcRem(metricsPN.spacing['4XS']),
            textDecorationColor: colorsPN.text.brand,
            textDecorationThickness: calcRem(2),
        },
        [breakpointMax('lg')]: {
            padding: calcRem(metricsPN.spacing['S']),
        },
    } as const)

export const StyledPrimaryNavListItem = styled('li')(() => ({
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
}))

export const StyledPrimaryNavButton = styled.button<StyledListItemProps>(
    ({ isOpen }) => ({
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        ...getParentNavStyles(isOpen),
        '&:active': {
            color: colorsPN.text.pressed,
        },
    }),
)

export const StyledPrimaryNavLink = styled(WebNavLink)<StyledListItemProps>(
    ({ isOpen }) => ({
        ...getParentNavStyles(isOpen),
    }),
)
