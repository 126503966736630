import React from 'react'
import { DataLayerEventName, NavEvent } from '@news-mono/web-common'
import { PNSubNavLinkNGN, PNNavLinkNGN } from '../../../PNNavigation'
import { StyledSubNavLink, StyledSubNavListItem } from './SecondaryNav.styled'
import { Location } from 'history'

interface SecondaryNavItemProps {
    parent: PNNavLinkNGN
    navLink: PNSubNavLinkNGN
    linkTabIndex: number
    onEvent: (event: NavEvent) => void
    setIsOpen: (isOpen: boolean) => void
}

export const SecondaryNavItem: React.FC<SecondaryNavItemProps> = ({
    parent,
    navLink,
    linkTabIndex,
    onEvent,
    setIsOpen,
}) => {
    function navLinkClicked() {
        setIsOpen(false)
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: 'SubNav',
            payload: {
                navName: 'Default.PerthNow',
                navLocation: 'Header',
                navText: navLink.name,
                navLink: navLink.link,
                navPath: `${parent.name} > ${navLink.name}`,
            },
        })
    }

    return (
        <StyledSubNavListItem tabIndex={-1}>
            <StyledSubNavLink
                tabIndex={linkTabIndex}
                to={navLink.link}
                onClick={navLinkClicked}
                activeClassName="is-active"
                isActive={(_match: any, location: Location) =>
                    location.pathname === navLink.link
                }
                openExternalInNewWindow={true}
                forceInternalSSR={navLink.forceInternalSSR}
                aria-label={navLink.name}
            >
                {navLink.name}
            </StyledSubNavLink>
        </StyledSubNavListItem>
    )
}
