import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { StyledArticleCard } from '../../nextgen-news/perthnow/cards/PNArticleCard/PNArticleCard.styled'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint, calcRem } from '../../__styling/style-functions'
import { ca } from 'date-fns/locale'

export interface StyledStellaProps {
    largeCardSpan: number
}

const rowGap = metricsPN.spacing['M']

const columnStart = 1
const columnTotalMobile = 5

const mainColumnsDesktop = 4
const sideColumnsDesktop = 3
const columnTotalDesktop = mainColumnsDesktop + sideColumnsDesktop

export const StyledStella = styled('div')<StyledStellaProps>(() => ({
    marginBottom: calcRem(metricsPN.spacing['L'] * 2),
    display: 'grid',
    position: 'relative',
    gridTemplateRows: 'auto 1fr',
    alignItems: 'start',
    gridTemplateColumns: `repeat(${columnTotalMobile}, 1fr)`,
    gridColumnGap: calcRem(metricsPN.spacing['L']),
    gridRowGap: calcRem(rowGap * 2),

    [`& ${StyledArticleCard}::after`]: {
        content: `''`,
        backgroundColor: colorsPN.stroke.weak,
        width: '100%',
        height: 1,
        position: 'relative',
        left: 0,
        bottom: calcRem(-rowGap),
        zIndex: zIndex.common.collectionDividers,
        gridColumn: `1 / -1`,
    },

    [breakpoint('sm')]: {
        rowGap: 0,
        marginBottom: calcRem(metricsPN.layout.section['tablet']),
    },

    [breakpoint('lg')]: {
        gridTemplateColumns: `repeat(${columnTotalDesktop}, 1fr)`,
        gridColumnGap: calcRem(metricsPN.spacing['L']),
        marginBottom: calcRem(metricsPN.layout.section['desktop']),

        [`& ${StyledArticleCard}:last-child::after`]: {
            display: 'none',
        },
    },
}))

export const StyledMain = styled('div')({
    gridColumnStart: columnStart,
    gridColumnEnd: columnTotalMobile + 1,
    display: 'grid',
    gridRowGap: calcRem(rowGap * 2),

    [breakpoint('lg')]: {
        gridColumnStart: columnStart,
        gridColumnEnd: columnTotalMobile,
    },
})

/** Three-card stack */
export const StyledCardStack = styled('div')({
    gridColumnStart: columnStart,
    gridColumnEnd: columnTotalMobile + 1,

    display: 'grid',
    gridRowGap: calcRem(rowGap * 2),

    [`& ${StyledArticleCard}:last-child::after`]: {
        display: 'none',
    },

    [breakpoint('lg')]: {
        gridColumnStart: mainColumnsDesktop + 1,
        gridColumnEnd: columnTotalDesktop + 1,
    },
})
