import {
    isNextGenPNEnabled,
    PerthNowSection,
} from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { getNGNTopicPageRouteInfo } from '../topic/get-ngn-topic-page-route-info'
import { getShopNowPageRouteInfo } from './shopNow'

const topic: Topic = {
    id: 'shop-now',
    title: 'Shop Now',
    parent: { id: 'shop-now', title: 'Shop Now', metadata: {}, seoTitle: '' },
    metadata: {},
    seoTitle: '',
    seoDescription: 'Great Discounts from PerthNow',
}

// Routing file for shop now topic page
export const shopNow: StaticRoutes<PerthNowSection> = {
    '/shop-now': ({ getAdTargeting, store }) => {
        const topicPageRedesignEnabled = isNextGenPNEnabled(
            store.getState().toggles,
            'perthnow-ngn-topic-disabled',
        )

        if (topicPageRedesignEnabled) {
            return getNGNTopicPageRouteInfo({
                publicationKind: 'article',
                topic: topic,
                section: 'shop-now',
                getAdTargeting,
            })
        }

        return getShopNowPageRouteInfo(getAdTargeting, topic, store)
    },
}
