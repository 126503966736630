import {
    PerthNowSection,
    sectionThemeOverrider,
} from '@news-mono/component-library'
import {
    GetRouteAdTargeting,
    PageType,
    Store,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { getPromoLayout } from '../common/promoLayout'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'

const bannerImage = require('./assets/pn-shopnow-banner.png')

export const getShopNowPageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    topic: Topic,
    store: Store,
): PageType<PerthNowSection> => {
    const areTaboolaSkimlinksEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'taboola-skimlinks',
    )
    return {
        kind: 'page',
        heading: 'Shop Now',
        pageType: 'topic',
        pageMeta: {
            description: 'Great Discounts from PerthNow',
        },
        adTargeting: getAdTargeting('home', 'shop-now', topic),
        section: 'shop-now',
        compositions: [
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'image-banner',
                            props: {
                                defaultSrc: bannerImage,
                                verticalGutters: ['unset', 'md'],
                                altText: 'Image',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'zeus',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'lima',
                            props: {
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                finalColumns: 1,
                                hideByline: true,
                                verticalSpacing: 'sm',
                                cardLayout: [['teaserLargeTitle']],
                                disclaimerText:
                                    'This content is reviewed by the PerthNow Editorial team. Seven West Media may receive a commission when you buy from links on our site. Prices correct at time of publication.',
                                allowLiveBlogMilestoneTeaser: true,
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'shop-now',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: false,
                                verticalSpacing: 'md',
                                teaserMode: 'visible',

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'shop-now',
                                    offset: 1,
                                    pageSize: 4,
                                },
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                fixedRatios: ['4:3', '16:9'],
                                finalColumns: 4,
                                fontScale: 0.75,
                                hideByline: true,
                                verticalSpacing: 'md',
                                remainingCardLayout: 'teaserSmallTitle',

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'shop-now',
                                    offset: 5,
                                    pageSize: 4,
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },

                                hideByline: true,
                                teaserMode: 'visible',
                                verticalSpacing: 'md',

                                sectionHeader: {
                                    heading: 'fashion',
                                    headingUrl: '/shop-now/shopnow-fashion',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['shop-now/shopnow-fashion'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },

                                hideByline: true,
                                teaserMode: 'visible',
                                verticalSpacing: 'md',

                                sectionHeader: {
                                    heading: 'beauty',
                                    headingUrl: '/shop-now/shopnow-beauty',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['shop-now/shopnow-beauty'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },

                                hideByline: true,
                                teaserMode: 'visible',
                                verticalSpacing: 'md',

                                sectionHeader: {
                                    heading: 'home & garden',
                                    headingUrl: '/shop-now/shopnow-home-garden',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['shop-now/shopnow-home-garden'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },

                                hideByline: true,
                                teaserMode: 'visible',
                                verticalSpacing: 'md',

                                sectionHeader: {
                                    heading: 'electronics',
                                    headingUrl: '/shop-now/shopnow-electronics',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['shop-now/shopnow-electronics'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },

                                hideByline: true,
                                teaserMode: 'visible',
                                verticalSpacing: 'md',

                                sectionHeader: {
                                    heading: 'travel',
                                    headingUrl: '/shop-now/shopnow-travel',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['shop-now/shopnow-travel'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },

                                hideByline: true,
                                teaserMode: 'visible',
                                verticalSpacing: 'md',

                                sectionHeader: {
                                    heading: 'lifestyle',
                                    headingUrl: '/shop-now/shopnow-lifestyle',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['shop-now/shopnow-lifestyle'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                    },
                                },
                            },
                        }),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-top',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        getPromoLayout('lifestyle'),
                        layout.nestedComposition({
                            type: 'scheme-overrider',
                            props: {
                                override: sectionThemeOverrider(
                                    'perthnow',
                                    'lifestyle',
                                ),
                            },
                            contentAreas: {
                                children: [
                                    areTaboolaSkimlinksEnabled
                                        ? layout.component({
                                              type: 'taboola',
                                              props: {
                                                  taboolaContainerId:
                                                      'tbla-content-recirc-sidebar',
                                                  locationType: 'category',
                                              },
                                          })
                                        : layout.component({
                                              type: 'juliet',
                                              props: {
                                                  cardType: {
                                                      type: 'landscape',
                                                      format: 'landscape-common',
                                                  },
                                                  removeHorizontalGutters: true,
                                                  verticalSpacing: 'md',
                                                  sectionHeader: {
                                                      heading: 'Lifestyle',
                                                  },
                                                  dataDefinitionArgs: {
                                                      type: 'listing',
                                                      topics: ['lifestyle'],
                                                      // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                                      publicationKind:
                                                          'article',
                                                      paging: {
                                                          page: 1,
                                                          pageSize: 5,
                                                      },
                                                  },
                                                  kickerMode: 'hidden',
                                              },
                                          }),
                                ],
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    hasBackgroundFill: false,
                    horizontalGutters: true,
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
