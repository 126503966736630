import {
    isNextGenPNEnabled,
    PerthNowSection,
} from '@news-mono/component-library'
import { PageType, StaticRoutes, Store } from '@news-mono/web-common'
import { getAussiePomcastPageRouteInfo } from './aussie-pomcast/get-aussie-pomcast-page-route'
import { getBushfiresPageRouteInfo } from './get-bushfires-page-route'
import { getCleoPageRouteInfo } from './get-cleo-page-route'
import { getCustomTopicPageRouteInfo } from './get-custom-topic-page-route'
import { getSharkPageRouteInfo } from './get-shark-page-route'
import { getTrafficPageRouteInfo } from './get-traffic-page-route'
import { getBestAustralianShortFilmRouteInfo } from './best-australian-short-film/get-best-australian-short-film-route'
import {
    getNGNTopicPageRouteInfo,
    GetTopicPageRouteInfoProps,
} from '../topic/get-ngn-topic-page-route-info'

const getPageRoute = (
    store: Store,
    {
        topic,
        publicationKind,
        section,
        getAdTargeting,
        topics,
    }: GetTopicPageRouteInfoProps,
): PageType<PerthNowSection> => {
    const topicPageRedesignEnabled = isNextGenPNEnabled(
        store.getState().toggles,
        'perthnow-ngn-topic-disabled',
    )

    if (topicPageRedesignEnabled) {
        return getNGNTopicPageRouteInfo({
            getAdTargeting,
            topic,
            topics,
            section,
        })
    } else {
        const topicListing: string[] = topics
            ? [topic.id, ...topics]
            : [topic.id]

        return getCustomTopicPageRouteInfo(
            getAdTargeting,
            topic,
            topicListing,
            publicationKind,
            section,
        )
    }
}

export const customTopicRoutes: StaticRoutes<PerthNowSection> = {
    '/entertainment/best-australian-short-film':
        getBestAustralianShortFilmRouteInfo,
    '/podcasts/aussie-pomcast': getAussiePomcastPageRouteInfo,
    '/news/cleo-smith': getCleoPageRouteInfo,
    '/news/perth-traffic': getTrafficPageRouteInfo,
    '/news/western-australia-beaches': getSharkPageRouteInfo,
    '/news/bushfire-alerts': getBushfiresPageRouteInfo,
    '/features': ({ getAdTargeting, store }) =>
        getPageRoute(store, {
            getAdTargeting: getAdTargeting,
            topic: {
                id: 'features',
                title: 'Special Features',
                seoTitle: 'Special Features',
                seoDescription:
                    'Exclusive features from PerthNow and The Sunday Times',
                metadata: {},
            },
            publicationKind: 'article',
            section: 'default',
        }),
    '/entertainment/reviews': ({ getAdTargeting, store }) =>
        getPageRoute(store, {
            getAdTargeting: getAdTargeting,
            topic: {
                id: 'entertainment/movie-reviews',
                title: 'Reviews',
                seoTitle: 'Reviews',
                seoDescription:
                    'Entertainment reviews from PerthNow and The Sunday Times',
                metadata: {},
                parent: {
                    id: 'entertainment',
                    title: 'Entertainment',
                    seoTitle: 'Entertainment',
                    metadata: {},
                },
            },
            topics: [
                'entertainment/fringe-world-reviews',
                'entertainment/piaf-reviews',
                'entertainment/tv-reviews',
                'entertainment/music-reviews',
                'entertainment/book-reviews',
                'entertainment/arts-reviews',
                'entertainment/theatre-reviews',
            ],
            publicationKind: 'article',
            section: 'entertainment',
        }),
    '/lifestyle/home-garden': ({ getAdTargeting, store }) =>
        getPageRoute(store, {
            getAdTargeting: getAdTargeting,
            topic: {
                id: 'lifestyle/home-decorating',
                title: 'Home & Garden',
                seoTitle: 'Home & Garden',
                seoDescription:
                    'Home and garden tips and features from PerthNow and The Sunday Times',
                metadata: {},
                parent: {
                    id: 'lifestyle',
                    title: 'Lifestyle',
                    seoTitle: 'Lifestyle',
                    metadata: {},
                },
            },
            topics: [
                'lifestyle/garden',
                'lifestyle/diy',
                'lifestyle/renovating',
                'lifestyle/bathrooms',
                'lifestyle/kitchens',
            ],
            publicationKind: 'article',
            section: 'lifestyle',
        }),
    '/sport/rugby': ({ getAdTargeting, store }) =>
        getPageRoute(store, {
            getAdTargeting: getAdTargeting,
            topic: {
                id: 'sport/rugby-union',
                title: 'Rugby Union',
                seoTitle: 'Rugby Union',
                seoDescription:
                    'The latest Rugby Union news from PerthNow and The Sunday Times',
                metadata: {},
                parent: {
                    id: 'sport',
                    title: 'Sport',
                    seoTitle: 'Sport',
                    metadata: {},
                },
            },
            topics: [
                'sport/western-force',
                'sport/wallabies',
                'sport/rugby-world-cup',
            ],
            publicationKind: 'article',
            section: 'sport',
        }),
    '/news/national': ({ getAdTargeting, store }) =>
        getPageRoute(store, {
            getAdTargeting: getAdTargeting,
            topic: {
                id: 'news/australia',
                title: 'National News',
                seoTitle: 'National News',
                seoDescription:
                    'The latest national news from PerthNow and The Sunday Times',
                metadata: {},
                parent: {
                    id: 'news',
                    title: 'News',
                    seoTitle: 'News',
                    metadata: {},
                },
            },
            publicationKind: 'article',
            section: 'news',
        }),
}
