import {
    isNextGenPNEnabled,
    PerthNowSection,
} from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    RouteResolution,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { getNGNTopicPageRouteInfo } from '../topic/get-ngn-topic-page-route-info'
import { getNewsPageRouteInfo } from './news'

const getNewsTopic = (resolution: RouteResolution): Topic => {
    const topic: Topic = {
        id: getResolvedTopicPageMeta(resolution)?.id || 'news',
        metadata: {},
        title:
            getResolvedTopicPageMeta(resolution)?.title ||
            getResolvedTopicPageMeta(resolution)?.seoTitle ||
            '',
        seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
        seoDescription:
            getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
    }

    return topic
}
// Routing file for news topic page
export const news: StaticRoutes<PerthNowSection> = {
    '/news': ({ getAdTargeting, store, resolution }) => {
        const topicPageRedesignEnabled = isNextGenPNEnabled(
            store.getState().toggles,
            'perthnow-ngn-topic-disabled',
        )

        const topic = getNewsTopic(resolution)

        if (topicPageRedesignEnabled) {
            return getNGNTopicPageRouteInfo({
                publicationKind: 'article',
                topic: topic,
                section: 'news',
                getAdTargeting,
            })
        }

        return getNewsPageRouteInfo(getAdTargeting, topic)
    },
}
