import { metrics, PerthNowSection } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType, Topic } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { getPromoLayout } from '../common/promoLayout'
import { getSidebarCollection } from '../lib/get-sidebar-collection'
import { sidebarCollectionWANewsNoHeader } from '../common/sidebarCollection'
import { perthNowSectionOverrider } from '../common/sectionTheme'
import { makeMiloCollection } from '../common/miloCollection'

export const getEntertainmentPageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    topic: Topic,
): PageType<PerthNowSection> => ({
    kind: 'page',
    heading: 'Entertainment',
    pageType: 'topic',
    pageMeta: {
        description:
            "The latest entertainment news featuring movie reviews, TV show coverage, celebrity gossip and the latest from Perth's arts scene.",
    },
    adTargeting: getAdTargeting('home', 'entertainment', topic),
    section: 'entertainment',
    compositions: [
        layout.composition({
            type: 'zeus',
            props: {},
            contentAreas: {
                main: [
                    perthNowSectionOverrider('entertainment', [
                        layout.component({
                            type: 'section-header',
                            props: {
                                heading: 'Confidential',
                                headingUrl: '/entertainment/confidential',
                                hasBottomMargin: true,
                            },
                        }),
                        layout.component({
                            type: 'portrait',
                            props: {
                                disableImageLazyLoad: true,
                                largeKicker: true,
                                mediaMode: 'edgeToEdge',
                                teaserMode: 'visible',
                                willPlayVideoInline: true,
                                fullWidthOnMobile: false,
                                kickerPosition: 'topleftinset',
                                hideByline: true,
                                hasPadding: true,
                                verticalSpacing: 'md',
                                fixedRatio: '16:9',
                                fontScale: 2,
                                isLarge: true,

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['entertainment/confidential'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 1,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                hiddenUntilLoaded: true,
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'mobile-mrec-one',
                                    size: 'mobileMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                fixedRatios: {
                                    default: ['161:229', '316:421'],
                                    sm: '4:3',
                                },
                                initialColumns: 1,
                                finalColumns: 3,
                                hideByline: true,
                                verticalSpacing: 'md',
                                remainingCardLayout: 'tallPortrait',

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['entertainment/confidential'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                        pageOffset: 1,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                verticalSpacing: 'md',
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },
                                teaserMode: 'visible',

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['entertainment/confidential'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                        pageOffset: 4,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'section-header',
                            props: {
                                heading: 'Entertainment',
                                hasBottomMargin: true,
                            },
                        }),
                        layout.component({
                            type: 'portrait',
                            props: {
                                disableImageLazyLoad: true,
                                largeKicker: true,
                                mediaMode: 'edgeToEdge',
                                teaserMode: 'visible',
                                willPlayVideoInline: true,
                                fullWidthOnMobile: false,
                                kickerPosition: 'topleftinset',
                                hideByline: true,
                                hasPadding: true,
                                verticalSpacing: 'md',
                                fixedRatio: '16:9',
                                fontScale: 2,
                                isLarge: true,

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['entertainment'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 1,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                hiddenUntilLoaded: true,
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'mobile-mrec-one',
                                    size: 'mobileMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                fixedRatios: {
                                    default: ['161:229', '316:421'],
                                    sm: '4:3',
                                },
                                initialColumns: 1,
                                finalColumns: 3,
                                hideByline: true,
                                verticalSpacing: 'md',
                                remainingCardLayout: 'tallPortrait',

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['entertainment'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                        pageOffset: 1,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                verticalSpacing: 'md',
                                showLargeCard: true,
                                largeCard: {
                                    largeCardHasBackground: true,
                                },
                                teaserMode: 'visible',

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['entertainment'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 3,
                                        pageOffset: 4,
                                    },
                                },
                            },
                        }),
                        makeMiloCollection(
                            'Television',
                            '/entertainment/tv',
                            ['entertainment/tv'],
                            'entertainment',
                        ),
                        makeMiloCollection(
                            'Movies',
                            '/entertainment/movies',
                            ['entertainment/movies'],
                            'entertainment',
                        ),
                        makeMiloCollection(
                            'Music',
                            '/entertainment/music',
                            ['entertainment/music'],
                            'entertainment',
                        ),
                        makeMiloCollection(
                            'Reviews',
                            '/entertainment/reviews',
                            [
                                'entertainment/fringe-world-reviews',
                                'entertainment/piaf-reviews',
                                'entertainment/movie-reviews',
                                'entertainment/tv-reviews',
                                'entertainment/music-reviews',
                                'entertainment/book-reviews',
                                'entertainment/arts-reviews',
                                'entertainment/theatre-reviews',
                            ],
                            'entertainment',
                        ),
                    ]),
                ],
                sidebar: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'sidebar-top',
                                size: 'desktopMrecHalfPage',
                            },
                            adType: 'inline',
                        },
                    }),
                    getPromoLayout(topic.parent ? topic.parent.id : topic.id),
                    getSidebarCollection({
                        section: 'sport',
                        currentSection: 'entertainment',
                        heading: 'Latest Sport News',
                        topics: ['sport'],
                        pageSize: 7,
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'sidebar-middle',
                                size: 'desktopMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    sidebarCollectionWANewsNoHeader({
                        pageSize: 8,
                        offset: 12,
                    }),
                ],
            },
        }),
    ],
})
