import React, {
    RefObject,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import {
    StickyHeaderHeightMobileProp,
    StickyHeaderHeightProp,
    StyledHeaderContainer,
    StyledNavContainer,
    StyledPNStickyContainer,
} from '../../content/PNStickySiteHeader/PNStickySiteHeader.styled'
import {
    addListener,
    removeListener,
    ScrollEventListener,
} from '../../__helpers/global-dom-events'
import { DimensionProps, withDimensions } from '../../__helpers/with-dimensions'
import { StickyAccountBannerWrapper } from '../../user-registration/warden/sticky-account-banner/StickyAccountBanner'
import { FeatureToggle } from '../../feature-toggling'
import { useFeature } from '@news-mono/web-common'

export interface PNStickySiteHeaderProps {
    header: JSX.Element
    navigation: JSX.Element
    secondaryNav?: JSX.Element
    disableSticky?: boolean
    headerRowRef?: RefObject<HTMLDivElement>
    ngnRedesignLeaderboardAdRef?: RefObject<HTMLDivElement>
    ngnRedesignHeaderLogoRowRef?: RefObject<HTMLDivElement>
}

type InternalProps = PNStickySiteHeaderProps &
    DimensionProps &
    RouteComponentProps<any>

/*
    PerthNow's navigation currently has three phases
    phase0 - visible
    phase1 - Banner (ad) hidden
    phase2 - all hidden
 */
export type Phase = 'phase0' | 'phase1' | 'phase2'

export const InternalPNStickySiteHeader: React.FC<InternalProps> = ({
    header,
    navigation,
    secondaryNav,
    disableSticky,
    headerRowRef,
    ngnRedesignLeaderboardAdRef,
    ngnRedesignHeaderLogoRowRef,
    height,
    width,
    innerRef,
}) => {
    const [phase, setPhase] = useState<Phase>('phase0')
    const bannerRef = useRef<HTMLDivElement>(null)
    const adRef = useRef<HTMLDivElement>(null)
    const phaseOffsets = useRef({
        phase0: 120,
        phase1: 800,
        phase2: 1600,
    })

    const TWHeaderRowHeight = headerRowRef?.current
        ? headerRowRef.current.getBoundingClientRect().height
        : 38
    const NGNHeaderLogoRowHeight = ngnRedesignHeaderLogoRowRef?.current
        ? ngnRedesignHeaderLogoRowRef.current.getBoundingClientRect().height
        : 0
    const NGNLeaderboardHeight = ngnRedesignLeaderboardAdRef?.current
        ? ngnRedesignLeaderboardAdRef.current.getBoundingClientRect().height
        : 82

    const redesignEnabled = useFeature('perthnow-ngn-redesign-master', false)

    const scrollHandler: ScrollEventListener = useCallback(
        ({ lastScrollPosition }) => {
            const bannerTop = bannerRef.current
                ? bannerRef.current.getBoundingClientRect().bottom
                : 0
            const bannerScrollPosition = lastScrollPosition - bannerTop
            let newPhase: Phase = 'phase0'
            if (bannerScrollPosition >= phaseOffsets.current.phase1) {
                newPhase = 'phase2'
            } else if (bannerScrollPosition >= phaseOffsets.current.phase0) {
                newPhase = 'phase1'
            } else {
                newPhase = 'phase0'
            }

            if (newPhase !== phase) {
                setPhase(newPhase)
            }
        },
        [phase],
    )

    useEffect(() => {
        if (disableSticky) {
            return
        }
        addListener('scroll', scrollHandler)
        return () => removeListener('scroll', scrollHandler)
    }, [disableSticky, scrollHandler])

    useEffect(() => {
        // height value to be hidden on mobile and tablet
        let heightValueMobile = 0

        // height value to be hidden on desktop
        let heightValueDesktop = 0

        let stickyBannerHeight = 0

        if (phase === 'phase0') {
            if (bannerRef.current) {
                stickyBannerHeight =
                    bannerRef.current.getBoundingClientRect().height -
                    NGNHeaderLogoRowHeight
            }
        } else if (phase === 'phase1') {
            if (!redesignEnabled) {
                if (adRef.current) {
                    heightValueDesktop =
                        -adRef.current.getBoundingClientRect().height
                }
                if (adRef.current && bannerRef.current) {
                    stickyBannerHeight =
                        bannerRef.current.getBoundingClientRect().height -
                        adRef.current.getBoundingClientRect().height -
                        0.2 // Weird rounding causing a small gap at this phase without this correction
                }
            } else {
                heightValueDesktop = -TWHeaderRowHeight - NGNLeaderboardHeight
                // Should count in the height of logo header on tablet and mobile
                heightValueMobile =
                    -TWHeaderRowHeight -
                    NGNLeaderboardHeight -
                    NGNHeaderLogoRowHeight
            }
        } else if (phase === 'phase2') {
            heightValueDesktop = redesignEnabled
                ? -TWHeaderRowHeight - NGNLeaderboardHeight
                : -height - TWHeaderRowHeight
            // Should count in the height of logo header on tablet and mobile
            heightValueMobile =
                -TWHeaderRowHeight -
                NGNLeaderboardHeight -
                NGNHeaderLogoRowHeight
        }

        if (bannerRef.current) {
            bannerRef.current.style.setProperty(
                StickyHeaderHeightProp,
                `${heightValueDesktop}px`,
            )
            bannerRef.current.style.setProperty(
                StickyHeaderHeightMobileProp,
                `${heightValueMobile}px`,
            )
        }
        document.documentElement.style.setProperty(
            '--stickyHeaderHeight',
            `${stickyBannerHeight}px`,
        )
    }, [
        phase,
        height,
        TWHeaderRowHeight,
        NGNLeaderboardHeight,
        redesignEnabled,
        NGNHeaderLogoRowHeight,
        width,
    ])

    return (
        <>
            <StyledPNStickyContainer
                className="js-ts-sticky-container"
                disableSticky={disableSticky}
                ref={bannerRef}
                phase={phase}
            >
                <div className="js-ts-nav-container" ref={innerRef}>
                    <StyledHeaderContainer className="js-ts-nav" ref={adRef}>
                        {header}
                    </StyledHeaderContainer>
                    {!redesignEnabled && (
                        <StyledNavContainer redesignEnabled={redesignEnabled}>
                            {navigation}
                            {secondaryNav}
                        </StyledNavContainer>
                    )}
                </div>
            </StyledPNStickyContainer>

            <FeatureToggle
                feature={'perthnow-new-auth-tenant'}
                on={() => (
                    <StickyAccountBannerWrapper
                        isHidden={false}
                        message="Join PerthNow for full access."
                        initialBannerText={`Resend email verification`}
                    />
                )}
            />
        </>
    )
}

export const PNStickySiteHeader = withRouter(
    withDimensions<PNStickySiteHeaderProps & RouteComponentProps<any>>(
        InternalPNStickySiteHeader,
    ),
)
PNStickySiteHeader.displayName = 'PNStickySiteHeader'
