import { AppState, UserAuthEvents, useFeature } from '@news-mono/web-common'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useAuthActions } from '../../user-registration'
import {
    StyledAction,
    StyledActionLink,
    StyledActionLinks,
    StyledSkeleton,
} from './PNAuthLogin.styled'
import { PNHeaderLogin } from './PNHeaderLoginModal'

export const PNAuthLoginActionLinks: React.FC<{
    onEvent: (event: UserAuthEvents) => void
}> = ({ onEvent }) => {
    const authentication = useSelector(
        (state: AppState) => state.authentication,
    )

    const { hasAttemptedValidation } = authentication

    if (!hasAttemptedValidation) {
        return (
            <StyledActionLinks>
                <StyledSkeleton height={32} width={60} count={1} />
            </StyledActionLinks>
        )
    }

    return (
        <StyledActionLinks>
            <PNHeaderLogin onEvent={onEvent} authState={authentication} />
        </StyledActionLinks>
    )
}

export const PNAuthLoginActionLinksOld: React.FC<{
    onEvent: (event: UserAuthEvents) => void
}> = ({ onEvent }) => {
    const { userName, isLoggedIn, hasAttemptedValidation } = useSelector(
        (state: AppState) => state.authentication,
    )

    if (!hasAttemptedValidation) {
        return (
            <StyledActionLinks>
                <StyledSkeleton height={20} count={1} />
                <StyledSkeleton height={20} count={1} />
            </StyledActionLinks>
        )
    }

    return (
        <StyledActionLinks>
            {!isLoggedIn ? (
                <ActionLinksUnauthenticated />
            ) : (
                <ActionLinksAuthenticated onEvent={onEvent} name={userName} />
            )}
        </StyledActionLinks>
    )
}

export const ActionLinksUnauthenticated: React.FC = () => {
    const { onLoginClick, onRegisterClick } = useAuthActions()
    return (
        <Fragment>
            <StyledActionLink onClick={() => onLoginClick('login-modal')}>
                Log In
            </StyledActionLink>
            <StyledActionLink
                onClick={() => onRegisterClick('login-modal', 'web')}
            >
                Register
            </StyledActionLink>
        </Fragment>
    )
}
export interface ActionLinksAuthenticatedProps {
    name: string | undefined
    onEvent: (event: any) => void
}

export const ActionLinksAuthenticated: React.FC<ActionLinksAuthenticatedProps> =
    ({ name, onEvent }) => {
        const { onLogoutClick } = useAuthActions()
        return (
            <React.Fragment>
                <StyledAction>Hey {name}</StyledAction>
                <StyledActionLink onClick={() => onLogoutClick(onEvent)}>
                    Log Out
                </StyledActionLink>
            </React.Fragment>
        )
    }
