import { PerthNowSection } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getPromoLayout } from '../../common/promoLayout'
import { sidebarCollectionCNGDontMiss } from '../../common/sidebarCollection'

export function localNewsCompetitionsPage(
    topic: Topic,
    pnlnTopic: Topic,
    {
        getAdTargeting,
    }: {
        getAdTargeting: GetRouteAdTargeting
    },
): PageType<PerthNowSection> {
    return {
        kind: 'page',
        heading: 'Competitions & Puzzles',
        pageType: 'topic',
        adTargeting: getAdTargeting('home', 'local-news', topic),
        pageMeta: {
            title: 'Competitions & Puzzles',
            description: 'A chance to WIN!',
        },
        section: 'local-news',
        compositions: [
            layout.composition({
                type: 'zeus',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'juliet',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'community-news-competitions',
                                    offset: 0,
                                    pageSize: 1,
                                },
                                showLargeCard: true,
                                kickerMode: 'hidden',
                                hideByline: true,
                                cardType: 'LandscapeWide',
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'community-news-competitions',
                                    offset: 1,
                                    pageSize: 2,
                                },
                                fontScale: 1.2,
                                fixedRatios: ['4:3'],
                                initialColumns: 1,
                                intermediateColumns: 1,
                                finalColumns: 2,
                                hasBackground: true,
                                hideByline: true,
                                kickerMode: 'hidden',
                                verticalSpacing: 'md',
                                remainingCardLayout: 'teaserLargeTitle',

                                minimumNumberOfCards: 2,
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'community-news-competitions',
                                    offset: 3,
                                    pageSize: 4,
                                },
                                fontScale: 1.1,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 1,
                                finalColumns: 2,
                                hideByline: true,
                                kickerMode: 'hidden',
                                teaserMode: 'hidden',
                                remainingCardLayout:
                                    'smallTitleNoKickerEdgeToEdge',

                                minimumNumberOfCards: 4,
                            },
                        }),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-top',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        getPromoLayout(pnlnTopic.id),
                        sidebarCollectionCNGDontMiss({
                            pageSize: 3,
                            offset: 0,
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-middle',
                                    size: 'desktopMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'zeus',
                props: { hasBackgroundFill: false },
                contentAreas: {
                    main: [
                        getPromoLayout(pnlnTopic.id, { mobileOnly: true }),
                        layout.component({
                            type: 'taboola',
                            feature: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                            },
                        }),
                    ],
                    sidebar: [],
                },
            }),
        ],
    }
}
