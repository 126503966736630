import {
    CardBreakpointRatios,
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
    FixedRatio,
    Product,
} from '@news-mono/web-common'
import React from 'react'
import {
    LoadMore,
    LoadMoreRouteProps,
} from '../../data-controllers/LoadMore/LoadMore'
import {
    calculateExpectedCardCountFromContentData,
    CollectionRouteProps,
} from '../collections-props.routing'
import { Romeo } from './Romeo'
import { ResponsivePictureSizes } from '../../content/Picture/responsive'
import {
    ImageMode,
    HeaderSectionTagType,
    PNFontScales,
    KickerMode,
    PNArticleCardProps,
} from '../../nextgen-news/perthnow/cards/PNArticleCard'
import { PNSectionHeaderProps } from '../../nextgen-news/perthnow/PNSectionHeader/PNSectionHeader'
import { TeaserMode } from '../../cards/CardText/CardTeaser'
import { DisplayOnBreakpoint, StyledRomeoWrapper } from './Romeo.styled'

export interface RomeoRouteProps
    extends CollectionRouteProps,
        LoadMoreRouteProps {
    hideByline?: boolean
    hideCardFooter?: boolean
    showLargeCard?: boolean
    timeStamped?: boolean
    fixedRatios?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    imageWidths?: ResponsivePictureSizes
    removeHorizontalGutters?: boolean
    disableImageLazyLoad?: boolean
    noHorizontalDividers?: boolean
    topicLevel?: 'parent'
    hideTopDivider?: boolean
    hideLastDivider?: boolean
    cardSpacing?: number
    hideOnMobile?: boolean
    fontScale?: PNFontScales
    teaserFontScale?: PNFontScales
    headerFontScale?: PNFontScales
    sectionHeader?: PNSectionHeaderProps
    imageMode?: ImageMode
    teaserMode?: TeaserMode
    kickerMode?: KickerMode
    showAuthor?: boolean
    horizontalDividerColor?: string
    displayOnBreakpoint?: DisplayOnBreakpoint
    marginTop?: number
    marginBottom?: number
    maxWidth?: number
    showCardTopic?: boolean
    headerSectionTag?: HeaderSectionTagType
    centreOnTablet?: boolean
    headerFontOverride?: PNArticleCardProps['headerFontOverride']
    imageSizeOverride?: PNArticleCardProps['imageSizeOverride']
}

export const RomeoRegistration = createRegisterableComponentWithData(
    'romeo',
    ContentDataDefinitionLoader,
    (props: RomeoRouteProps, data, services) => {
        return (
            <LoadMore
                dataDefinition={data.dataDefinitionArgs}
                services={services}
                loadMoreEnabled={props.loadMoreEnabled === true}
                data={data}
                onLoadMore={(loadMoreData) => {
                    services.onEvent(
                        createCollectionAvailableEvent(
                            loadMoreData.result.publications,
                            'Romeo',
                            Product.PerthNow,
                            services.onEvent,
                        ),
                    )
                }}
                render={(loadMoreData) => {
                    return (
                        <StyledRomeoWrapper>
                            <Romeo
                                items={
                                    loadMoreData.loaded
                                        ? {
                                              loaded: true,
                                              result: loadMoreData.result
                                                  .publications,
                                          }
                                        : { loaded: false }
                                }
                                expectedCards={calculateExpectedCardCountFromContentData(
                                    data.dataDefinitionArgs,
                                )}
                                sectionHeader={props.sectionHeader}
                                hideByline={props.hideByline}
                                hideCardFooter={props.hideCardFooter}
                                horizontalDividerColor={
                                    props.horizontalDividerColor
                                }
                                fixedRatios={props.fixedRatios}
                                imageWidths={props.imageWidths}
                                showLargeCard={props.showLargeCard}
                                isTimestamped={props.timeStamped}
                                verticalSpacing={
                                    props.loadMoreEnabled
                                        ? props.verticalSpacing
                                        : 'md'
                                }
                                disableImageLazyLoad={
                                    props.disableImageLazyLoad
                                }
                                onEvent={services.onEvent}
                                noHorizontalDividers={
                                    props.noHorizontalDividers
                                }
                                topicLevel={props.topicLevel}
                                hideTopDivider={props.hideTopDivider}
                                hideLastDivider={props.hideLastDivider}
                                cardSpacing={props.cardSpacing}
                                displayOnBreakpoint={props.displayOnBreakpoint}
                                fontScale={props.fontScale}
                                teaserFontScale={props.teaserFontScale}
                                headerFontScale={props.headerFontScale}
                                imageMode={props.imageMode}
                                teaserMode={props.teaserMode}
                                showAuthor={props.showAuthor}
                                marginTop={props.marginTop}
                                marginBottom={props.marginBottom}
                                showCardTopic={props.showCardTopic}
                                headerSectionTag={props.headerSectionTag}
                                centreOnTablet={props.centreOnTablet}
                                kickerMode={props.kickerMode}
                                headerFontOverride={props.headerFontOverride}
                                imageSizeOverride={props.imageSizeOverride}
                            />
                        </StyledRomeoWrapper>
                    )
                }}
            />
        )
    },
)
