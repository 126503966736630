import styled from '@emotion/styled'
import {
    calcRem,
    zIndex,
    breakpointMax,
    breakpoint,
} from '@news-mono/component-library'
import { metricsPN, tokens } from '@news-mono/design-tokens'
import { keyframes } from '@emotion/react'
import { WebLink } from '@news-mono/web-common'
import { colorsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../mixins/TextMixin/TextMixin.theme'
// Styling for component fade-in & fade-out
const componentFadeIn = keyframes({
    '0%': {
        opacity: 0,
    },
    '100%': {
        opacity: 1,
    },
})

export const StyledAppDownloadContainer = styled(WebLink)(({ theme }) => ({
    alignItems: 'flex-start',
    alignSelf: 'center',
    borderRadius: calcRem(metricsPN.radius['S']),
    background: colorsPN.background.brand.strong,
    textDecoration: 'none',
    marginRight: calcRem(metricsPN.spacing['2XS']),
    whiteSpace: 'nowrap',
    p: {
        display: 'flex',
        height: calcRem(34),
        padding: calcRem(metricsPN.spacing['2XS'], metricsPN.spacing['XS']),
        justifyContent: 'center',
        alignItems: 'center',
        gap: calcRem(metricsPN.spacing['2XS']),
        margin: 0,
        color: colorsPN.text.inverse,
        ...perthNowNGNTextMixin.label.M.semibold,
    },
    display: 'none',
    '&:hover': {
        backgroundImage: `linear-gradient(${colorsPN.fill.hover.default} 0 0)`,
    },
    '&:active': {
        backgroundImage: `linear-gradient(${colorsPN.fill.pressed.default} 0 0)`,
    },
    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['4XS']),
    },
    [breakpoint('xxs')]: {
        display: 'flex',
    },
}))

export const StyledAuthLink = styled.button(() => ({
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
}))

// Primary container for the whole login modal
export const StyledLoginModalContainer = styled.div<{ isLoggedIn: boolean }>(
    ({ isLoggedIn }) => ({
        position: 'absolute',
        background: 'white',
        border: `1px solid ${colorsPN.stroke.weak}`,

        width: calcRem(isLoggedIn ? 213 : 208),
        height: 'auto',
        borderRadius: calcRem(metricsPN.radius['L']),

        display: 'flex',
        flexDirection: 'column',

        zIndex: zIndex.thenightly.stickyCreateAccountModal,

        top: calcRem(40),
        right: calcRem(12),
        animation: `${componentFadeIn} 0.15s`,

        boxShadow:
            '0px 6px 4px -2px rgba(16, 24, 40, 0.03), 0px 6px 12px -4px rgba(16, 24, 40, 0.08)',
    }),
)

// Styling for the top "information" section of the login modal
export const StyledInformationHeader = styled('h2')((props) => ({
    ...perthNowNGNTextMixin.label.L.semibold,
    color: colorsPN.text.primary,
    margin: 0,
    maxWidth: '90%',

    // Truncated text formatting
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}))

export const StyledInformationSecondaryText = styled.p(() => ({
    margin: 0,
    ...perthNowNGNTextMixin.label.S.regular,
    color: colorsPN.text.secondary,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}))

export const LoginStateInformationContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(metricsPN.spacing['2XS']),
    padding: calcRem(metricsPN.spacing['S']),
    paddingBottom: calcRem(metricsPN.spacing['XS']),
}))

export const ButtonContainer = styled('div')<{ isLoggedIn: boolean }>(
    ({ isLoggedIn }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: isLoggedIn ? undefined : calcRem(metricsPN.spacing['XS']),
        padding: calcRem(metricsPN.spacing['S']),
        paddingTop: isLoggedIn ? calcRem(metricsPN.spacing['4XS']) : undefined,
        paddingBottom: isLoggedIn
            ? calcRem(metricsPN.spacing['4XS'])
            : undefined,
    }),
)

export const Divider = styled('div')(() => ({
    height: calcRem(1),
    backgroundColor: tokens.perthnow.colors.palette.greyDivider,
}))

export const ButtonText = styled('div')(() => ({
    ...perthNowNGNTextMixin.label.M.semibold,
}))

export const PNLoginButtonContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    button: {
        padding: calcRem(0, metricsPN.spacing['XS']),
        borderRadius: `${metricsPN.radius['S']}px`,
        whiteSpace: 'nowrap',
        ...perthNowNGNTextMixin.label.M.semibold,
        '&:focus-visible': {
            outline: 'revert',
            outlineOffset: calcRem(metricsPN.spacing['4XS']),
        },
    },
    [breakpointMax('lg')]: {
        padding: calcRem(metricsPN.spacing['2XS'], 0),
    },
}))
