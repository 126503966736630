import {
    isNextGenPNEnabled,
    PerthNowSection,
} from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { getNGNTopicPageRouteInfo } from '../topic/get-ngn-topic-page-route-info'
import { getEntertainmentPageRouteInfo } from './entertainment'

const entertainmentTopic: Topic = {
    id: 'entertainment',
    title: 'Entertainment',
    parent: {
        id: 'entertainment',
        title: 'Entertainment',
        metadata: {},
        seoTitle: '',
    },
    metadata: {},
    seoTitle: '',
    seoDescription:
        "The latest entertainment news featuring movie reviews, TV show coverage, celebrity gossip and the latest from Perth's arts scene.",
}
// Routing file for entertainment topic page
export const entertainment: StaticRoutes<PerthNowSection> = {
    '/entertainment': ({ getAdTargeting, store }) => {
        const topicPageRedesignEnabled = isNextGenPNEnabled(
            store.getState().toggles,
            'perthnow-ngn-topic-disabled',
        )

        if (topicPageRedesignEnabled) {
            return getNGNTopicPageRouteInfo({
                publicationKind: 'article',
                topic: entertainmentTopic,
                section: 'entertainment',
                getAdTargeting,
            })
        }

        return getEntertainmentPageRouteInfo(getAdTargeting, entertainmentTopic)
    },
}
