import { metrics } from '@news-mono/component-library'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../App.routing'

/**
 * Helper to create multiple ad units to display a mrec on mobile,
 * and billboard/leaderboards on tablet/desktop
 **/
export function billboardLeaderboardMrec(
    id: string,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    id: string,
    bottomPadding: number,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    mrecId: string,
    pageType: string,
    bottomPadding?: number,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    mrecId: string,
    pageTypeOrBottomPadding?: string | number,
    bottomPadding?: number,
): Array<CheckedComponentInformation> {
    const actualBottomPadding =
        typeof pageTypeOrBottomPadding === 'number'
            ? pageTypeOrBottomPadding
            : bottomPadding || metrics.perthnow.margins.xl

    const slotIdPrefix =
        typeof pageTypeOrBottomPadding === 'string'
            ? pageTypeOrBottomPadding
            : ''

    return [
        layout.component({
            type: 'ad-unit',
            props: {
                noticePosition: 'below-center',
                hiddenUntilLoaded: true,
                padding: [0, 0, actualBottomPadding, 0],
                slot: {
                    id: `${slotIdPrefix}-mrec-${mrecId}`,
                    size: 'below768Mrec',
                },
                adType: 'inline',
            },
        }),
        layout.component({
            type: 'ad-unit',
            props: {
                noticePosition: 'below-center',
                hiddenUntilLoaded: true,
                padding: [0, 0, actualBottomPadding, 0],
                slot: {
                    id: `${slotIdPrefix}-leaderboard-${mrecId}`,
                    size: 'leaderBoardAbove768',
                },
                adType: 'inline',
            },
        }),
        layout.component({
            type: 'ad-unit',
            props: {
                noticePosition: 'below-center',
                hiddenUntilLoaded: true,
                padding: [0, 0, actualBottomPadding, 0],
                slot: {
                    id: `${slotIdPrefix}-billboard-${mrecId}`,
                    size: 'billBoardAbove1280',
                },
                adType: 'inline',
            },
        }),
    ]
}
