import {
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
    Product,
} from '@news-mono/web-common'
import React from 'react'
import { LoadMore } from '../../data-controllers/LoadMore/LoadMore'
import { calculateExpectedCardCountFromContentData } from '../collections-props.routing'
import { PNTopicCardList } from './PNTopicCardList'
import { StyledTopicListWrapper } from './PNTopicCardList.styled'
import { RomeoRouteProps } from '../PNRomeo/Romeo.routing'

export interface PNTopicListRouteProps extends RomeoRouteProps {
    adUnits: number
}

export const PNTopicCardListRegistration = createRegisterableComponentWithData(
    'pn-topic-card-list',
    ContentDataDefinitionLoader,
    (props: PNTopicListRouteProps, data, services) => {
        return (
            <LoadMore
                dataDefinition={data.dataDefinitionArgs}
                services={services}
                loadMoreEnabled={props.loadMoreEnabled === true}
                data={data}
                onLoadMore={(loadMoreData) => {
                    services.onEvent(
                        createCollectionAvailableEvent(
                            loadMoreData.result.publications,
                            'Romeo',
                            Product.PerthNow,
                            services.onEvent,
                        ),
                    )
                }}
                render={(loadMoreData) => {
                    return (
                        <StyledTopicListWrapper>
                            <PNTopicCardList
                                items={
                                    loadMoreData.loaded
                                        ? {
                                              loaded: true,
                                              result: loadMoreData.result
                                                  .publications,
                                          }
                                        : { loaded: false }
                                }
                                expectedCards={calculateExpectedCardCountFromContentData(
                                    data.dataDefinitionArgs,
                                )}
                                sectionHeader={props.sectionHeader}
                                hideByline={props.hideByline}
                                hideCardFooter={props.hideCardFooter}
                                horizontalDividerColor={
                                    props.horizontalDividerColor
                                }
                                fixedRatios={props.fixedRatios}
                                imageWidths={props.imageWidths}
                                showLargeCard={props.showLargeCard}
                                isTimestamped={props.timeStamped}
                                verticalSpacing={
                                    props.loadMoreEnabled
                                        ? props.verticalSpacing
                                        : 'md'
                                }
                                disableImageLazyLoad={
                                    props.disableImageLazyLoad
                                }
                                onEvent={services.onEvent}
                                noHorizontalDividers={
                                    props.noHorizontalDividers
                                }
                                topicLevel={props.topicLevel}
                                hideTopDivider={props.hideTopDivider}
                                hideLastDivider={props.hideLastDivider}
                                cardSpacing={props.cardSpacing}
                                displayOnBreakpoint={props.displayOnBreakpoint}
                                fontScale={props.fontScale}
                                teaserFontScale={props.teaserFontScale}
                                headerFontScale={props.headerFontScale}
                                imageMode={props.imageMode}
                                teaserMode={props.teaserMode}
                                showAuthor={props.showAuthor}
                                marginTop={props.marginTop}
                                marginBottom={props.marginBottom}
                                showCardTopic={props.showCardTopic}
                                headerSectionTag={props.headerSectionTag}
                                centreOnTablet={props.centreOnTablet}
                                kickerMode={props.kickerMode}
                                headerFontOverride={props.headerFontOverride}
                                imageSizeOverride={props.imageSizeOverride}
                                adState={services.adState}
                                adUnits={props.adUnits}
                            />
                        </StyledTopicListWrapper>
                    )
                }}
            />
        )
    },
)
