import {
    isNGNTopicPageRedesignEnabled,
    PerthNowSection,
} from '@news-mono/component-library'
import {
    GetRouteAdTargeting,
    StaticRoutes,
    Store,
    Topic,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { createPnlnFacebookBannerComponent } from '../lib/create-localnews-facebook-banner-component'
import { getNGNTopicPageRouteInfo } from '../topic/get-ngn-topic-page-route-info'
import {
    localNewsCompetitionsPage,
    localNewsHomepage,
    localNewsMasthead,
    localNewsRegion,
} from './pages'

export const localNewsTopics = {
    north: [
        'local-news/perthnow-stirling',
        'local-news/perthnow-joondalup',
        'local-news/perthnow-wanneroo',
    ],
    central: [
        'local-news/perthnow-western-suburbs',
        'local-news/perthnow-central',
        'local-news/perthnow-southern',
    ],
    south: [
        'local-news/perthnow-canning',
        'local-news/perthnow-cockburn',
        'local-news/perthnow-fremantle',
        'local-news/perthnow-melville',
    ],
}

export type PnlnMasthead =
    | 'perthnow-western-suburbs'
    | 'perthnow-stirling'
    | 'perthnow-central'
    | 'perthnow-joondalup'
    | 'perthnow-wanneroo'
    | 'perthnow-canning'
    | 'perthnow-cockburn'
    | 'perthnow-fremantle'
    | 'perthnow-melville'
    | 'perthnow-southern'

const localNewsTopic: Topic = {
    id: 'local-news',
    title: 'Local News',
    metadata: {},
    seoTitle: '',
    seoDescription: `Local News brings you the best and latest local news from all over Perth. All our editions cover the latest Perth news, lifestyle, sport, motoring, real estate as well as events for families, residents & businesses.`,
}

const localNewsCompTopic: Topic = {
    id: 'local-news/competitions',
    title: 'Competitions & Puzzles',
    parent: localNewsTopic,
    metadata: {},
    seoTitle: '',
}

const getLocalNewsPage = (
    store: Store,
    getAdTargeting: GetRouteAdTargeting,
    region: Topic,
    type: 'masthead' | 'region',
    banner?: CheckedComponentInformation,
) => {
    if (isNGNTopicPageRedesignEnabled(store)) {
        return getNGNTopicPageRouteInfo({
            getAdTargeting,
            topic: region,
            section: 'local-news',
            adTopic: localNewsTopic,
        })
    }

    if (type === 'masthead') {
        return localNewsMasthead(localNewsTopic, {
            getAdTargeting,
            region,
            topics: [region.id],
            facebookBanner: banner,
        })
    } else {
        return localNewsRegion(localNewsTopic, {
            getAdTargeting,
            region,
            topics: [region.id],
        })
    }
}

export const localNews: StaticRoutes<PerthNowSection> = {
    '/local-news': ({ config, getAdTargeting, store }) => {
        if (isNGNTopicPageRedesignEnabled(store)) {
            return getNGNTopicPageRouteInfo({
                getAdTargeting,
                topic: localNewsTopic,
                section: 'local-news',
            })
        }

        return localNewsHomepage(localNewsTopic, { config, getAdTargeting })
    },
    '/local-news/perthnow-western-suburbs': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-western-suburbs',
            title: 'PerthNow Western Suburbs',
            seoDescription: `Latest local Perth news, lifestyle, sport, motoring & real estate for families, residents & businesses in Perth's Western Suburbs.`,
            metadata: {},
            seoTitle: '',
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-western-suburbs'),
        )
    },
    '/local-news/perthnow-stirling': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-stirling',
            title: 'PerthNow Stirling',
            seoDescription: `Latest local Perth news, lifestyle, sport, motoring & real estate for families, residents & businesses in the City of Stirling.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-stirling'),
        )
    },
    '/local-news/perthnow-central': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-central',
            title: 'PerthNow Central',
            seoDescription: `Latest local Perth news, lifestyle, sport, motoring & real estate for families, residents & businesses in Perth's Central Suburbs.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-central'),
        )
    },
    '/local-news/perthnow-joondalup': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-joondalup',
            title: 'PerthNow Joondalup',
            seoDescription: `Latest local Joondalup news, opinion, lifestyle, sport, motoring & real estate for families, residents & businesses in City of Joondalup.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-joondalup'),
        )
    },
    '/local-news/perthnow-wanneroo': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-wanneroo',
            title: 'PerthNow Wanneroo',
            seoDescription: `Latest local Wanneroo news, opinion, lifestyle, sport, motoring & real estate for families, residents & businesses in the City of Wanneroo.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-wanneroo'),
        )
    },
    '/local-news/perthnow-canning': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-canning',
            title: 'PerthNow Canning',
            seoDescription: `Latest local Canning news, opinion, lifestyle, sport, motoring and real estate for families, residents and businesses in the City of Canning.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-canning'),
        )
    },
    '/local-news/perthnow-cockburn': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-cockburn',
            title: 'PerthNow Cockburn',
            seoDescription: `Latest local Cockburn news, opinion, lifestyle, sport, motoring & real estate for families, residents & businesses in the City of Cockburn.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-cockburn'),
        )
    },

    '/local-news/perthnow-fremantle': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-fremantle',
            title: 'PerthNow Fremantle',
            seoDescription: `Latest local news, lifestyle, sport, motoring & real estate for families, residents & businesses in City of Fremantle & East Fremantle.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-fremantle'),
        )
    },
    '/local-news/perthnow-melville': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-melville',
            title: 'PerthNow Melville',
            seoDescription: `Latest local Melville news, opinion, lifestyle, sport, motoring & real estate for families, residents & businesses in the City of Melville.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-melville'),
        )
    },
    '/local-news/perthnow-southern': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/perthnow-southern',
            title: 'PerthNow Southern',
            seoDescription: `Latest local news, lifestyle, sport & real estate for residents & businesses in the City of Belmont, City of South Perth & Victoria Park.`,
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(
            store,
            getAdTargeting,
            region,
            'masthead',
            createPnlnFacebookBannerComponent('perthnow-southern'),
        )
    },
    '/local-news/competitions': ({ getAdTargeting, store }) => {
        if (isNGNTopicPageRedesignEnabled(store)) {
            return getNGNTopicPageRouteInfo({
                getAdTargeting,
                topic: localNewsCompTopic,
                section: 'local-news',
                adTopic: localNewsTopic,
            })
        }

        return localNewsCompetitionsPage(localNewsCompTopic, localNewsTopic, {
            getAdTargeting,
        })
    },
    '/local-news/north': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/north',
            title: 'North',
            seoDescription: 'The Latest Community news in the North Region',
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(store, getAdTargeting, region, 'region')
    },

    '/local-news/central': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/central',
            title: 'Central',
            seoDescription: 'The Latest Community news in the Central Region',
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(store, getAdTargeting, region, 'region')
    },
    '/local-news/south': ({ getAdTargeting, store }) => {
        const region: Topic = {
            id: 'local-news/south',
            title: 'South',
            seoDescription: 'The Latest Community news in the South Region',
            seoTitle: '',
            metadata: {},
        }

        return getLocalNewsPage(store, getAdTargeting, region, 'region')
    },
}
