import { PerthNowSection } from '@news-mono/component-library'
import {
    GetRouteAdTargeting,
    PageType,
    Store,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getPromoLayout } from '../../common/promoLayout'
import { getOlympicsSegment } from '../../custom/olympics-segment'

export const getParalympicsPageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
    topic: Topic,
): PageType<PerthNowSection> => {
    return {
        kind: 'page',
        heading: 'Paralympics',
        pageType: 'topic',
        pageMeta: {
            title: 'Paralympics',
            description: 'Paralympics news and updates from PerthNow',
        },
        adTargeting: getAdTargeting('home', 'sport', topic),
        section: 'sport',
        compositions: [
            layout.composition({
                type: 'zeus',
                props: {
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [
                        ...getOlympicsSegment(store.getState().toggles, true),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalGutters: ['md', 'unset'],
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'juliet',
                                        props: {
                                            cardType: 'LandscapeWide',
                                            showLargeCard: false,
                                            verticalSpacing: 'sm',
                                            disableImageLazyLoad: true,
                                            hideByline: true,
                                            loadMoreEnabled: true,
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/paralympics'],
                                                includeSubTopics: true,
                                                publicationKind: 'article',
                                                paging: {
                                                    page: 1,
                                                    pageSize: 4,
                                                },
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                            },
                        }),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                hiddenUntilLoaded: true,
                                slot: {
                                    id: 'sidebar-two',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        getPromoLayout('sport'),
                        layout.component({
                            type: 'juliet',
                            props: {
                                cardType: {
                                    type: 'landscape',
                                    format: 'landscape-common',
                                },
                                removeHorizontalGutters: true,
                                verticalSpacing: 'md',
                                sectionHeader: {
                                    heading: 'Latest Sport News',
                                    section: 'sport',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport'],
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 7,
                                    },
                                },
                                kickerMode: 'hidden',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    hasBackgroundFill: false,
                    horizontalGutters: true,
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [getPromoLayout('sport', { mobileOnly: true })],
                },
            }),
        ],
    }
}
