import {
    PerthNowSection,
    SectionHeaderNavLink,
} from '@news-mono/component-library'
import { layout } from '../../App.routing'
import { perthNowSectionOverrider } from './sectionTheme'

export const makeMiloCollection = (
    heading: string,
    headingUrl: string,
    topics: string[],
    section: PerthNowSection,
    navLinks?: SectionHeaderNavLink[],
    listingItemCount = 5,
) => {
    return perthNowSectionOverrider(section, [
        layout.component({
            type: 'milo',
            props: {
                showLargeCard: true,
                largeCard: {
                    largeCardHasBackground: true,
                    largeCardSpan: 2,
                },

                verticalSpacing: 'lg',
                teaserMode: 'visible',

                sectionHeader: {
                    heading,
                    headingUrl,
                    navLinks,
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: topics,
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 0,
                        pageSize: listingItemCount,
                    },
                },
            },
        }),
    ])
}
