import { metrics, PerthNowSection } from '@news-mono/component-library'
import {
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { outnowBanner } from '../../common/outnow-banner'
import { getPromoLayout } from '../../common/promoLayout'
import { perthNowSectionOverrider } from '../../common/sectionTheme'
import {
    sidebarCollectionAroundTheSite,
    sidebarCollectionCNGDontMiss,
} from '../../common/sidebarCollection'
import { createMarketingLinkComponent } from '../../lib/create-marketing-link-component'
import { localNewsTopics } from '..'

export function localNewsHomepage(
    localNewsTopic: Topic,
    options: {
        config: BaseClientConfig
        getAdTargeting: GetRouteAdTargeting
    },
): PageType<PerthNowSection> {
    const { getAdTargeting } = options

    return {
        kind: 'page',
        heading: localNewsTopic.title,
        pageType: 'topic',
        noMetaTitleTemplate: true,
        pageMeta: {
            title: `Perth News | Local News`,
            description: `Local News brings you the best and latest local news from all over Perth. All our editions cover the latest Perth news, lifestyle, sport, motoring, real estate as well as events for families, residents & businesses.`,
        },
        adTargeting: getAdTargeting('home', 'local-news', localNewsTopic),
        section: 'local-news',
        compositions: [
            layout.composition({
                type: 'zeus',
                props: {},
                contentAreas: {
                    main: [
                        outnowBanner,
                        layout.component({
                            type: 'milo',
                            props: {
                                disableImageLazyLoad: true,
                                verticalSpacing: 'md',
                                showLargeCard: true,
                                largeCard: {
                                    largeCardSpan: 2,
                                    largeCardHasBackground: true,
                                    largeCardPosition: 'left',
                                },
                                hideByline: true,
                                teaserMode: 'visible',

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'community-news',
                                    offset: 0,
                                    pageSize: 5,
                                },
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                hiddenUntilLoaded: true,
                                padding: [0, 0, metrics.perthnow.margins.md, 0],
                                slot: {
                                    id: 'local-news-mrec-one',
                                    size: 'mrec768below',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                fixedRatios: {
                                    default: ['161:229', '316:421'],
                                    sm: '4:3',
                                },
                                initialColumns: 1,
                                finalColumns: 3,
                                verticalSpacing: 'md',
                                hideByline: true,
                                remainingCardLayout: 'tallPortrait',

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'community-news',
                                    offset: 5,
                                    pageSize: 3,
                                },
                            },
                        }),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'md',
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'pnln-social-banner',
                                        props: {},
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                finalColumns: 1,
                                fontScale: 2,
                                hideByline: true,
                                verticalSpacing: 'sm',
                                cardLayout: [['teaserLargeTitle']],

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'community-news',
                                    offset: 8,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                finalColumns: 4,
                                verticalSpacing: 'md',
                                hideByline: true,

                                cardLayout: [
                                    ['teaserSmallTitle'],
                                    ['teaserSmallTitle'],
                                    ['teaserSmallTitle'],
                                    ['teaserSmallTitle'],
                                ],
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'community-news',
                                    offset: 9,
                                    pageSize: 4,
                                },
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                hiddenUntilLoaded: true,
                                padding: [0, 0, metrics.perthnow.margins.md, 0],
                                slot: {
                                    id: 'local-news-mrec-two',
                                    size: 'mrec768below',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.nestedComposition({
                            type: 'janus',
                            props: {
                                verticalSpacing: 'lg',
                                stretchColumns: true,
                            },
                            contentAreas: {
                                left: [
                                    createMarketingLinkComponent(
                                        'PnlnSendStoryTips',
                                    ),
                                ],
                                right: [
                                    createMarketingLinkComponent(
                                        'PnlnNewsletter',
                                    ),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'milo',
                            props: {
                                disableImageLazyLoad: true,
                                verticalSpacing: 'xs',
                                showLargeCard: true,
                                largeCard: {
                                    largeCardSpan: 2,
                                    largeCardHasBackground: true,
                                    largeCardPosition: 'left',
                                },
                                hideByline: true,
                                teaserMode: 'visible',

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'community-news',
                                    offset: 13,
                                    pageSize: 3,
                                },
                            },
                        }),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-top',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'curated-promotional-card',
                            props: {
                                fontScale: 1,
                                verticalSpacing: 'md',
                                fixedRatio: '16:9',
                                hasBackground: true,
                                hasPadding: true,
                                mediaMode: 'edgeToEdge',
                                dataDefinitionArgs: {
                                    type: 'active-promotion',
                                    promotionSlotId: 'local-news',
                                    metadataKey: 'local-news-promotional-card',
                                    offset: 0,
                                    pageSize: 1,
                                },
                                cardOrientation: {
                                    defaultLayout: 'portrait',
                                },
                            },
                        }),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'md',
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'pnln-social-banner',
                                        props: {
                                            compact: true,
                                        },
                                    }),
                                ],
                            },
                        }),
                        sidebarCollectionCNGDontMiss({
                            pageSize: 8,
                            offset: 0,
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                stickyOffset: 0,
                                slot: {
                                    id: 'sidebar-middle',
                                    size: 'desktopMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'none',
                                hiddenUntilLoaded: true,
                                padding: [0, 0, metrics.perthnow.margins.md, 0],
                                slot: {
                                    id: 'leaderboard-one',
                                    size: 'leaderboard768Above',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'none',
                                hiddenUntilLoaded: true,
                                padding: [0, 0, metrics.perthnow.margins.md, 0],
                                slot: {
                                    id: 'mrec-one',
                                    size: 'mrec768below',
                                },
                                adType: 'inline',
                            },
                        }),
                        perthNowSectionOverrider('local-news', [
                            layout.nestedComposition({
                                type: 'cerberus-four',
                                props: {
                                    verticalSpacing: 'md',
                                    stretchColumns: true,
                                },
                                contentAreas: {
                                    content1: [
                                        layout.component({
                                            type: 'oscar',
                                            props: {
                                                sectionHeader: {
                                                    heading: 'North',
                                                    headingUrl:
                                                        '/local-news/north',
                                                },
                                                collectionSection:
                                                    'community-news',
                                                disableImageLazyLoad: true,
                                                truncatedListCards: true,
                                                dataDefinitionArgs: {
                                                    type: 'listing',
                                                    topics: localNewsTopics.north,
                                                    includeSubTopics: true,
                                                    publicationKind: 'article',
                                                    paging: {
                                                        page: 1,
                                                        pageSize: 6,
                                                    },
                                                },
                                                relatedLinks: {
                                                    title: 'Our North Papers',
                                                    links: [
                                                        {
                                                            text: 'PerthNow - Stirling',
                                                            link: '/local-news/perthnow-stirling',
                                                        },
                                                        {
                                                            text: 'PerthNow - Joondalup',
                                                            link: '/local-news/perthnow-joondalup',
                                                        },
                                                        {
                                                            text: 'PerthNow - Wanneroo',
                                                            link: '/local-news/perthnow-wanneroo',
                                                        },
                                                    ],
                                                },
                                            },
                                        }),
                                    ],
                                    content2: [
                                        layout.component({
                                            type: 'oscar',
                                            props: {
                                                sectionHeader: {
                                                    heading: 'Central',
                                                    headingUrl:
                                                        '/local-news/central',
                                                },
                                                collectionSection: 'local-news',
                                                disableImageLazyLoad: true,
                                                truncatedListCards: true,
                                                dataDefinitionArgs: {
                                                    type: 'listing',
                                                    topics: localNewsTopics.central,
                                                    includeSubTopics: true,
                                                    publicationKind: 'article',
                                                    paging: {
                                                        page: 1,
                                                        pageSize: 6,
                                                    },
                                                },
                                                relatedLinks: {
                                                    title: 'Our Central Papers',
                                                    links: [
                                                        {
                                                            text: 'PerthNow - Western Suburbs',
                                                            link: '/local-news/perthnow-western-suburbs',
                                                        },
                                                        {
                                                            text: 'PerthNow - Central',
                                                            link: '/local-news/perthnow-central',
                                                        },
                                                        {
                                                            text: 'PerthNow - Southern',
                                                            link: '/local-news/perthnow-southern',
                                                        },
                                                    ],
                                                },
                                            },
                                        }),
                                    ],
                                    content3: [
                                        layout.component({
                                            type: 'oscar',
                                            props: {
                                                sectionHeader: {
                                                    heading: 'South',
                                                    headingUrl:
                                                        '/local-news/south',
                                                },
                                                collectionSection: 'local-news',
                                                disableImageLazyLoad: true,
                                                truncatedListCards: true,
                                                dataDefinitionArgs: {
                                                    type: 'listing',
                                                    topics: localNewsTopics.south,
                                                    includeSubTopics: true,
                                                    publicationKind: 'article',
                                                    paging: {
                                                        page: 1,
                                                        pageSize: 6,
                                                    },
                                                },
                                                relatedLinks: {
                                                    title: 'Our South Papers',
                                                    links: [
                                                        {
                                                            text: 'PerthNow - Canning',
                                                            link: '/local-news/perthnow-canning',
                                                        },
                                                        {
                                                            text: 'PerthNow - Cockburn',
                                                            link: '/local-news/perthnow-cockburn',
                                                        },
                                                        {
                                                            text: 'PerthNow - Fremantle',
                                                            link: '/local-news/perthnow-fremantle',
                                                        },
                                                        {
                                                            text: 'PerthNow - Melville',
                                                            link: '/local-news/perthnow-melville',
                                                        },
                                                    ],
                                                },
                                            },
                                        }),
                                    ],
                                    content4: [
                                        layout.component({
                                            type: 'oscar',
                                            props: {
                                                sectionHeader: {
                                                    heading: `Don't Miss`,
                                                },
                                                collectionSection: 'local-news',
                                                disableImageLazyLoad: true,
                                                truncatedListCards: true,
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'community-news-dont-miss',
                                                    offset: 0,
                                                    pageSize: 10,
                                                },
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ]),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: { hasBackgroundFill: false },
                contentAreas: {
                    main: [
                        getPromoLayout(
                            localNewsTopic.parent
                                ? localNewsTopic.parent.id
                                : localNewsTopic.id,
                            {
                                mobileOnly: true,
                            },
                        ),
                    ],
                },
            }),
            layout.composition({
                type: 'zeus',
                feature: 'taboola',
                props: { hasBackgroundFill: false, verticalSpacing: 'unset' },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-home-thumbnails',
                                locationType: 'home',
                            },
                        }),
                    ],
                    sidebar: [
                        sidebarCollectionAroundTheSite({
                            pageSize: 9,
                            offset: 0,
                        }),
                    ],
                },
            }),
        ],
    }
}
