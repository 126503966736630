import React, { useEffect, useRef } from 'react'
import { NavEvent } from '@news-mono/web-common'
import {
    StyledSubNavWrapper,
    StyledSubNavTopicHeader,
    StyledSubNav,
} from './SecondaryNav.styled'
import { SecondaryNavItem } from './SecondaryNavItem'
import { PNSubNavLinkNGN, PNNavLinkNGN } from '../../../PNNavigation'

export interface SecondaryNavProps {
    subNavLinks: PNSubNavLinkNGN[]
    parent: PNNavLinkNGN
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    onEvent: (event: NavEvent) => void
    tabIndex: number
}

export const SecondaryNav: React.FC<SecondaryNavProps> = ({
    subNavLinks,
    isOpen,
    parent,
    setIsOpen,
    onEvent,
    tabIndex,
}) => {
    const firstLinkRef = useRef<HTMLAnchorElement | null>(null)
    useEffect(() => {
        if (isOpen && firstLinkRef.current) {
            firstLinkRef.current.focus()
        }
    }, [isOpen])

    const linkTabIndex = isOpen ? tabIndex : -1
    const [firstItem, ...middleItems] = subNavLinks
    const lastItem = middleItems.pop()

    return (
        <StyledSubNavWrapper aria-label="Topics Section">
            <StyledSubNavTopicHeader>Topics</StyledSubNavTopicHeader>
            <StyledSubNav aria-label="Topics List">
                {firstItem && (
                    <SecondaryNavItem
                        parent={parent}
                        linkTabIndex={linkTabIndex}
                        navLink={firstItem}
                        onEvent={onEvent}
                        setIsOpen={setIsOpen}
                    />
                )}

                {middleItems.map((item, index) => (
                    <SecondaryNavItem
                        key={index}
                        parent={parent}
                        linkTabIndex={linkTabIndex}
                        navLink={item}
                        onEvent={onEvent}
                        setIsOpen={setIsOpen}
                    />
                ))}
                {lastItem && (
                    <SecondaryNavItem
                        parent={parent}
                        linkTabIndex={linkTabIndex}
                        navLink={lastItem}
                        onEvent={onEvent}
                        setIsOpen={setIsOpen}
                    />
                )}
            </StyledSubNav>
        </StyledSubNavWrapper>
    )
}
