import React from 'react'
import {
    StyledBylineAuthor,
    StyledBylinePosition,
    StyledBylineTextBlock,
    StyledBylineTextWrap,
    StyledFundingLabel,
    StyledMultipleTimestampWrapper,
} from '../../../../../publication/Byline/Byline.styled'
import { BylineContentProps } from '../../../../../publication/Byline/components/BylineContent'
import { BylineTimestamp } from '../../../../../publication/Byline/components/BylineTimestamp'
import { SourceLogo } from '../../../../../publication/Byline/components/SourceLogo'
import { BylineTimestampUpdated } from '../../../../../publication/Byline/components/BylineTimestampUpdated'

export const PNBylineContent: React.FC<BylineContentProps> = ({
    profiles,
    inlineTimestamp,
    fundingType,
    source,
    timestamp,
    timestampUpdated,
    timestampFormat,
    timestampUpdatedFormat,
    text,
    timestampUpdatedLabel,
    children,
}) => {
    const updateLabel = timestampUpdatedLabel || 'Updated'
    if (profiles && profiles.length > 0 && !fundingType) {
        const profileBlock = profiles.map((profile, index) => {
            const profileUrl = `/profile/${profile.slug}`
            const headShot = index === 0 ? children : null
            if (source) {
                return (
                    <StyledBylineTextWrap key={index}>
                        {headShot}
                        <StyledBylineAuthor
                            href={profileUrl}
                            itemScope
                            itemType="http://schema.org/Person"
                        >
                            <span itemProp="name">{profile.name}</span>
                        </StyledBylineAuthor>
                        <SourceLogo source={source} />
                        {inlineTimestamp && (
                            <StyledMultipleTimestampWrapper>
                                {timestamp && (
                                    <BylineTimestamp
                                        timestamp={timestamp}
                                        timestampFormat={timestampFormat}
                                    />
                                )}
                                {timestampUpdated && (
                                    <BylineTimestampUpdated
                                        timestamp={timestampUpdated}
                                        timestampLabel={updateLabel}
                                        timestampUpdatedFormat={
                                            timestampUpdatedFormat
                                        }
                                    />
                                )}
                            </StyledMultipleTimestampWrapper>
                        )}
                    </StyledBylineTextWrap>
                )
            } else if (profile.position) {
                return (
                    <StyledBylineTextWrap key={index}>
                        {headShot}
                        <StyledBylineAuthor
                            href={profileUrl}
                            itemScope
                            itemType="http://schema.org/Person"
                        >
                            <span itemProp="name">{profile.name}</span>
                        </StyledBylineAuthor>
                        <StyledBylinePosition itemProp="jobTitle">
                            {profile.position}
                        </StyledBylinePosition>
                    </StyledBylineTextWrap>
                )
            } else {
                return (
                    <StyledBylineAuthor
                        key={index}
                        href={profileUrl}
                        itemScope
                        itemType="http://schema.org/Person"
                    >
                        <span itemProp="name">{profile.name}</span>
                    </StyledBylineAuthor>
                )
            }
        })

        return <React.Fragment>{profileBlock}</React.Fragment>
    }

    if (fundingType) {
        if (profiles && profiles.length > 0) {
            const profileBlock = profiles.map((profile, index) => {
                const profileUrl = `/profile/${profile.slug}`
                const headShot = index === 0 ? children : null

                return (
                    <StyledBylineTextWrap key={index}>
                        {headShot}
                        {profile.name && (
                            <StyledBylineAuthor
                                href={profileUrl}
                                itemScope
                                itemType="http://schema.org/Person"
                            >
                                <span itemProp="name">{profile.name}</span>
                            </StyledBylineAuthor>
                        )}
                        <StyledFundingLabel fundingType={fundingType} />
                    </StyledBylineTextWrap>
                )
            })

            return <React.Fragment>{profileBlock}</React.Fragment>
        }
        return (
            <StyledBylineTextWrap>
                {children}
                {text && (
                    <StyledBylineTextBlock
                        itemScope
                        itemType="http://schema.org/Person"
                    >
                        <span itemProp="name">{text}</span>
                    </StyledBylineTextBlock>
                )}
                <StyledFundingLabel
                    fundingType={fundingType}
                    linkToDisclaimer
                />
            </StyledBylineTextWrap>
        )
    }

    if (text && source) {
        return (
            <StyledBylineTextWrap>
                {children}
                <StyledBylineTextBlock
                    itemScope
                    itemType="http://schema.org/Person"
                >
                    <span itemProp="name">{text}</span>
                </StyledBylineTextBlock>
                <SourceLogo source={source} />
                {inlineTimestamp && (
                    <StyledMultipleTimestampWrapper>
                        <BylineTimestamp
                            timestamp={timestamp}
                            timestampFormat={timestampFormat}
                        />
                        {timestampUpdated && (
                            <BylineTimestampUpdated
                                timestamp={timestampUpdated}
                                timestampUpdatedFormat={timestampUpdatedFormat}
                            />
                        )}
                    </StyledMultipleTimestampWrapper>
                )}
            </StyledBylineTextWrap>
        )
    } else if (text) {
        return (
            <StyledBylineTextBlock>
                {children}
                <StyledBylineTextBlock
                    itemScope
                    itemType="http://schema.org/Person"
                >
                    <span itemProp="name">{text}</span>
                </StyledBylineTextBlock>
                {inlineTimestamp && (
                    <StyledMultipleTimestampWrapper>
                        <BylineTimestamp
                            timestamp={timestamp}
                            timestampFormat={timestampFormat}
                        />
                        {timestampUpdated && (
                            <BylineTimestampUpdated
                                timestamp={timestampUpdated}
                                timestampUpdatedFormat={timestampUpdatedFormat}
                            />
                        )}
                    </StyledMultipleTimestampWrapper>
                )}
            </StyledBylineTextBlock>
        )
    } else if (source) {
        return (
            <StyledBylineTextBlock>
                {children}
                <SourceLogo source={source} />
                {inlineTimestamp && (
                    <StyledMultipleTimestampWrapper>
                        <BylineTimestamp
                            timestamp={timestamp}
                            timestampFormat={timestampFormat}
                        />
                        {timestampUpdated && (
                            <BylineTimestampUpdated
                                timestamp={timestampUpdated}
                                timestampUpdatedFormat={timestampUpdatedFormat}
                            />
                        )}
                    </StyledMultipleTimestampWrapper>
                )}
            </StyledBylineTextBlock>
        )
    }

    return null
}
