import styled from '@emotion/styled'
import {
    breakpoint,
    breakpointMax,
    calcRem,
    metrics,
} from '@news-mono/component-library'
import { tokens, colorsPN } from '@news-mono/design-tokens'

interface RedesignProps {
    redesignEnabled?: boolean
}

export const StyledBase = styled('div')<RedesignProps>(
    ({ redesignEnabled = false }) => {
        return [
            {
                backgroundColor: redesignEnabled
                    ? colorsPN.background.base
                    : tokens.perthnow.colors.palette.blackShade[5],
                position: 'relative',
                [breakpointMax('md')]: {
                    // Need to remove hidden overflow for mobile to allow for the sticky header
                    overflow: redesignEnabled ? undefined : 'hidden',
                },
            },
        ]
    },
)
StyledBase.displayName = 'StyledBase'

export const BaseGrid = styled('div')({
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,
    width: '100%',
})
BaseGrid.displayName = 'BaseGrid'

export const BaseRow = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',
    textAlign: 'left',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
})
BaseRow.displayName = 'BaseRow'

export const BaseColumn = styled('div')({
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexBasis: '100%',
    flex: '0 0 auto',
    flexWrap: 'nowrap',
    margin: 0,
    alignItems: 'stretch',
    justifyContent: 'center',
})
BaseColumn.displayName = 'BaseColumn'

export const NestedBaseGrid = styled('div')<RedesignProps>(
    ({ redesignEnabled = false }) => {
        return [
            {
                width: '100%',
                maxWidth: redesignEnabled
                    ? calcRem(metrics.perthnow.siteMetrics.mainContentWidthNGN)
                    : calcRem(metrics.perthnow.siteMetrics.mainContentWidth),
                paddingLeft: redesignEnabled ? 16 : 'unset',
                paddingRight: redesignEnabled ? 16 : 'unset',
                display: 'flex',
                flexBasis: '100%',
                flex: '0 0 auto',
                flexWrap: 'nowrap',
                margin: 0,
                alignItems: 'stretch',
                backgroundColor: redesignEnabled
                    ? colorsPN.background.base
                    : tokens.perthnow.colors.palette.blackShade[5],
            },
            redesignEnabled && {
                [breakpoint('md')]: {
                    paddingLeft: 24,
                    paddingRight: 24,
                },
            },
        ]
    },
)

export const NestedBaseRow = styled('div')({
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flex: '1 1 auto',
})

interface NestedBaseColumnProps {
    hasVerticalGutter?: boolean
    redesignEnabled?: boolean
}

export const NestedBaseColumn = styled('div')<NestedBaseColumnProps>(
    ({ redesignEnabled = false, hasVerticalGutter = false }) => {
        return [
            {
                width: '100%',
                maxWidth: '100%',
                display: 'flex',
                flexBasis: '100%',
                flex: '0 0 auto',
                flexWrap: 'nowrap',
                margin: 0,
                alignItems: 'stretch',
                backgroundColor: redesignEnabled
                    ? colorsPN.background.base
                    : tokens.perthnow.colors.palette.blackShade[5],
                padding: hasVerticalGutter ? calcRem(40, 0) : 0,
            },
        ]
    },
)

// AMP Only
export const StyledContent = styled('div')({
    background: colorsPN.background.base,
    boxSizing: 'border-box',
    padding: metrics.perthnow.outerMargin,
})
