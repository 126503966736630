import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { PerthNowSection } from '@news-mono/component-library'
import {
    GetRouteAdTargeting,
    PageType,
    Store,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getPromoLayout } from '../../common/promoLayout'
import { perthNowSectionOverrider } from '../../common/sectionTheme'
import { playOfTheDaySegment } from '../../home/home-layout'

export const getAFLPageRouteInfo = (
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
    topic: Topic,
): PageType<PerthNowSection> => ({
    kind: 'page',
    heading: 'AFL',
    hideHeading: false,
    pageType: 'topic',
    pageMeta: {
        title: 'AFL', // required otherwise AFL is Afl from topic.title
        description: 'Sport news and updates from PerthNow',
    },
    adTargeting: getAdTargeting('home', 'sport', topic),
    section: 'sport',
    compositions: [
        layout.composition({
            type: 'zeus',
            props: {},
            contentAreas: {
                main: [
                    layout.component({
                        type: 'lima',
                        props: {
                            fixedRatios: ['16:9'],
                            initialColumns: 1,
                            finalColumns: 1,
                            fontScale: 2,
                            hideByline: true,
                            verticalSpacing: 'sm',
                            cardLayout: [['teaserLargeTitle']],
                            allowLiveBlogMilestoneTeaser: true,
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/afl'],
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                includeSubTopics: true,
                                paging: {
                                    page: 1,
                                    pageOffset: 0,
                                    pageSize: 1,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'milo',
                        props: {
                            showLargeCard: false,
                            verticalSpacing: 'md',
                            teaserMode: 'visible',

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/afl'],
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                includeSubTopics: true,
                                paging: {
                                    page: 1,
                                    pageOffset: 1,
                                    pageSize: 4,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'mobile-mrec-one',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    layout.component({
                        type: 'lima',
                        props: {
                            fixedRatios: ['4:3', '16:9'],
                            finalColumns: 4,
                            fontScale: 0.75,
                            hideByline: true,
                            verticalSpacing: 'md',
                            remainingCardLayout: 'teaserSmallTitle',

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/afl'],
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                includeSubTopics: true,
                                paging: {
                                    page: 1,
                                    pageOffset: 5,
                                    pageSize: 4,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'milo',
                        props: {
                            showLargeCard: true,
                            largeCard: {
                                largeCardHasBackground: true,
                            },

                            hideByline: true,
                            verticalSpacing: 'md',
                            teaserMode: 'visible',

                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['sport/afl'],
                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                publicationKind: 'article',
                                paging: {
                                    page: 1,
                                    pageOffset: 10,
                                    pageSize: 3,
                                },
                            },
                        },
                    }),
                    layout.nestedComposition({
                        type: 'janus',
                        props: {
                            verticalSpacing: 'md',
                            stretchColumns: true,
                        },
                        contentAreas: {
                            left: [
                                perthNowSectionOverrider('sport', [
                                    layout.component({
                                        type: 'oscar',
                                        props: {
                                            sectionHeader: {
                                                heading: 'West Coast Eagles',
                                                headingUrl:
                                                    '/sport/west-coast-eagles',
                                            },
                                            collectionSection: 'sport',
                                            disableImageLazyLoad: true,
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: [
                                                    'sport/west-coast-eagles',
                                                ],
                                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                                publicationKind: 'article',
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 6,
                                                },
                                            },
                                        },
                                    }),
                                ]),
                            ],
                            right: [
                                perthNowSectionOverrider('sport', [
                                    layout.component({
                                        type: 'oscar',
                                        props: {
                                            sectionHeader: {
                                                heading: 'Fremantle Dockers',
                                                headingUrl:
                                                    '/sport/fremantle-dockers',
                                            },
                                            collectionSection: 'sport',
                                            disableImageLazyLoad: true,
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: [
                                                    'sport/fremantle-dockers',
                                                ],
                                                includeSubTopics: false,
                                                // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                                publicationKind: 'article',
                                                paging: {
                                                    page: 1,
                                                    pageSize: 6,
                                                },
                                            },
                                        },
                                    }),
                                ]),
                            ],
                        },
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            hiddenUntilLoaded: true,
                            padding: [0, 0, 13, 0],
                            slot: {
                                id: 'mobile-mrec-two',
                                size: 'mobileMrec',
                            },
                            adType: 'inline',
                        },
                    }),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'afl-team-flags',
                            props: {
                                verticalSpacing: 'md',
                                sectionHeader: {
                                    heading: 'Club News',
                                },
                            },
                        }),
                    ]),
                    layout.component({
                        type: 'taboola',
                        props: {
                            taboolaContainerId:
                                'taboola-below-category-thumbnails',
                            locationType: 'category',
                        },
                    }),
                ],
                sidebar: [
                    ...playOfTheDaySegment({
                        featureOverride: 'perthnow-play-of-the-day',
                        themeOverride: isFeatureEnabled(
                            toFeatureState(store.getState().toggles),
                            'perthnow-streamer-screamer-play-of-the-day',
                        )
                            ? 'streamer-screamer'
                            : undefined,
                    }),
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            padding: [0, 0, 13, 0],
                            hiddenUntilLoaded: true,
                            slot: {
                                id: 'sidebar-two',
                                size: 'desktopMrecHalfPage',
                            },
                            adType: 'inline',
                        },
                    }),
                    getPromoLayout('sport'),
                    perthNowSectionOverrider('sport', [
                        layout.component({
                            type: 'juliet',
                            props: {
                                cardType: {
                                    type: 'landscape',
                                    format: 'landscape-common',
                                },
                                removeHorizontalGutters: true,
                                verticalSpacing: 'md',
                                sectionHeader: {
                                    heading: 'Latest Sport News',
                                },
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport'],
                                    // Listing pages should only show article publications unless they are a curation, gallery or video collection
                                    publicationKind: 'article',
                                    paging: {
                                        page: 1,
                                        pageSize: 7,
                                    },
                                },
                                kickerMode: 'hidden',
                            },
                        }),
                    ]),
                ],
            },
        }),
        layout.composition({
            type: 'thor',
            props: {
                hasBackgroundFill: false,
                horizontalGutters: true,
                verticalGutters: false,
            },
            contentAreas: {
                main: [getPromoLayout('sport', { mobileOnly: true })],
            },
        }),
    ],
})
