import React, { useState, useRef, useCallback } from 'react'
import {
    AllEvents,
    DataLayerEventName,
    NavEventArgs,
} from '@news-mono/web-common'
import {
    PerthNowSection,
    breakpoints,
    useWindowWidth,
} from '@news-mono/component-library'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'
import {
    StyledPrimaryNavLink,
    StyledPrimaryNavListItem,
} from './PrimaryNavTopic.styled'
import { SecondaryMenuContainer } from './SecondaryMenuContainer'
import { PNSubNavLinkNGN, IsOpenState } from '../PNNavigation'
import { NewsletterFormInputHook } from '../../helpers/newsletterSignUp'
import { useLocation } from 'react-router'
import { Location } from 'history'
export interface PrimaryNavTopicProps {
    name: string
    link: string
    section: PerthNowSection
    subNavLinks?: PNSubNavLinkNGN[]
    toggleSubNav: (section: PerthNowSection, state: boolean) => void
    onEvent: (event: AllEvents) => void
    currentSection?: PerthNowSection
    pos: number
    subscribeLink: string
    findMyPaperLink: string
    forceInternalSSR?: boolean
    latestStories: ListPublicationV4DTO[]
    formHook: NewsletterFormInputHook
    recaptureSiteKey: string
    isOpen: IsOpenState
    setIsOpen: (isOpen: IsOpenState) => void
    initState: IsOpenState
}

export const PrimaryNavTopic: React.FC<PrimaryNavTopicProps> = ({
    name,
    link,
    section,
    subNavLinks = [],
    toggleSubNav,
    onEvent,
    pos,
    subscribeLink,
    findMyPaperLink,
    forceInternalSSR,
    latestStories,
    formHook,
    recaptureSiteKey,
    isOpen,
    setIsOpen,
    initState,
}) => {
    const refFocus = useRef<HTMLAnchorElement>(null)
    const [isTouchDevice, setIsTouchDevice] = useState(false)
    const [tabIndex, setTabIndex] = useState(-1)

    const windowWidth = useWindowWidth() ?? 0
    const isDesktopBreakpoint = windowWidth >= breakpoints.lg

    const handleClick = useCallback(
        (args: NavEventArgs, event: React.MouseEvent<HTMLAnchorElement>) => {
            onEvent({
                type: DataLayerEventName.navClicked,
                originator: 'PNMainNavigationItem',
                payload: {
                    navName: isDesktopBreakpoint
                        ? 'Default.PerthNow.MainNavigation.NGNLaunch'
                        : 'Default.PerthNow.MobileStickyMenu.NGNLaunch',
                    navLocation: 'Header',
                    navPos: args.navPos,
                    navText: args.navText,
                    navLink: args.navLink,
                    navPath: args.navText,
                },
            })

            if (!subNavLinks.length) return

            if (isTouchDevice) {
                event.preventDefault()
                setIsOpen({ ...initState, [section]: true })
                setTabIndex(-1)
                toggleSubNav(section, true)
            }
        },
        [
            initState,
            isDesktopBreakpoint,
            isTouchDevice,
            onEvent,
            section,
            setIsOpen,
            subNavLinks.length,
            toggleSubNav,
        ],
    )

    const handleTouchStart = useCallback(() => {
        setIsTouchDevice(true)
    }, [])

    const handleMouseEnter = useCallback(() => {
        setIsOpen({ ...initState, [section]: true })
        setTabIndex(-1)
        toggleSubNav(section, true)
    }, [initState, section, setIsOpen, toggleSubNav])

    const handleMouseLeave = useCallback(() => {
        setTabIndex(-1)
        toggleSubNav(section, false)
    }, [section, toggleSubNav])

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'ArrowDown' || event.key === 'Enter') {
            setTabIndex(0)
            refFocus.current?.focus()
            event.preventDefault()
        }
    }, [])

    const hideMenu = (show: boolean) => {
        setIsOpen({ ...initState, [section]: show })
    }

    const handleIsActive = (location: Location) => {
        const parentOrCurrent =
            location.pathname === link ||
            subNavLinks.some((subNavLink) => {
                return subNavLink.link === location.pathname
            }) ||
            `/${location.pathname.split('/')[1]}` === link

        return parentOrCurrent
    }

    return (
        <>
            <StyledPrimaryNavListItem
                onClick={handleMouseEnter} // This is a workaround for tablet devices
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onFocus={handleMouseEnter}
                onKeyDown={handleKeyDown}
                aria-haspopup="true"
                data-section-id={section}
            >
                <StyledPrimaryNavLink
                    isOpen={isOpen[section]}
                    onClick={(event) =>
                        handleClick(
                            {
                                navPos: pos,
                                navText: name,
                                navLink: link,
                            },
                            event,
                        )
                    }
                    onTouchStart={handleTouchStart}
                    isActive={(_match: any, location: Location) =>
                        handleIsActive(location)
                    }
                    to={link}
                >
                    {name}
                </StyledPrimaryNavLink>
            </StyledPrimaryNavListItem>
            {isDesktopBreakpoint && (
                <SecondaryMenuContainer
                    focusRef={refFocus}
                    subNavLinks={subNavLinks}
                    section={section}
                    subscribeLink={subscribeLink}
                    findMyPaperLink={findMyPaperLink}
                    parent={{
                        name,
                        link,
                        section,
                        subNavLinks,
                        forceInternalSSR,
                    }}
                    isOpen={isOpen[section]}
                    setIsOpen={hideMenu}
                    onEvent={onEvent}
                    aria-label="submenu"
                    latestStories={latestStories}
                    formHook={formHook}
                    recaptureSiteKey={recaptureSiteKey}
                    handleTouchStart={handleTouchStart}
                    link={link}
                    pos={pos}
                    tabIndex={tabIndex}
                />
            )}
        </>
    )
}
