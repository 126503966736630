import {
    adPaddingOptions,
    getInlineEventAds,
    inlineArticleAds,
    PerthNowSection,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Product,
    publicationAdKind,
    publicationAdPageType,
    RenderTarget,
    TogglesReduxState,
} from '@news-mono/web-common'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { getNoIndexMeta } from './allowList'
import { toFeatureState } from '@etrigan/feature-toggles-client'
import {
    sidebarCollectionCommunityNews,
    sidebarCollectionShopNow,
    sidebarCollectionYouMightLike,
} from '../../common/sidebarCollectionNGN'
import {
    getPerthNowSectionNav,
    getPerthNowSubTopicNavItems,
} from '../../common/ngn-topic-navigation'

export const createArticleRouteNgn = ({
    article,
    config,
    section,
    getAdTargeting,
    toggles,
}: {
    article: ArticleLikeV4DTO
    config: BaseClientConfig
    section: PerthNowSection
    getAdTargeting: GetRouteAdTargeting
    renderTarget: RenderTarget
    toggles?: TogglesReduxState
}): PageType<PerthNowSection> => {
    const featureState = toggles && toFeatureState(toggles)

    const isCommunityNews = section === 'community-news'
    const isShopNow = section === 'shop-now'

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        publicationAdKind[article.kind],
        section,
        article.topics.primary,
        ...article.topics.secondary,
    )

    const adTargeting: AdTargeting = {
        pageId: article.slug,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const extraInlineArticleAds = [
        inlineArticleAds({
            insertAfter: 3,
            noticePosition: 'below-center',
            hiddenUntilLoaded: false,
            mastHead: 'perthnow',
            slotID: 'article-mrec-one',
            size: 'alwaysMrec',
            pageType: publicationAdPageType[article.kind],
        }),
        inlineArticleAds({
            insertAfter: 12,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'perthnow',
            slotID: 'article-mrec-two',
            size: 'alwaysMrec',
            pageType: publicationAdPageType[article.kind],
        }),
        inlineArticleAds({
            insertAfter: 21,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'perthnow',
            slotID: 'article-mrec-three',
            size: 'alwaysMrec',
            pageType: publicationAdPageType[article.kind],
        }),
        inlineArticleAds({
            insertAfter: 30,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'perthnow',
            slotID: 'article-mrec-four',
            size: 'alwaysMrec',
            pageType: publicationAdPageType[article.kind],
        }),
    ]

    const sidebarCollection = () => {
        if (isCommunityNews) {
            return sidebarCollectionCommunityNews({ pageSize: 4 })
        } else if (isShopNow) {
            return sidebarCollectionShopNow({ pageSize: 9 })
        } else {
            return sidebarCollectionYouMightLike(
                {
                    pageSize: 3,
                    offset: 0,
                },
                [article.topics.primary.id],
            )
        }
    }

    return {
        kind: 'page',
        heading: article.heading,
        pageMeta: {
            title: article.seoHead || undefined,
            description: article.homepageTeaser,
            meta: getNoIndexMeta(article.source, featureState),
        },
        socialMeta: {
            title: article.socialHead || article.homepageHead,
            description: article.socialTeaser || article.homepageTeaser,
        },
        hideHeading: true,
        noMetaTitleTemplate: false,
        pageType: 'publication',
        adTargeting,
        section,
        additionalPageProperties: {
            primaryTopic: article.topics.primary,
            secondaryTopics: article.topics.secondary,
            publicationKind: article.kind,
        },
        compositions: [
            layout.composition({
                type: 'pn-hades',
                props: {
                    layout: 'article',
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'ad-unit',
                            feature: 'outstream',
                            props: {
                                noticePosition: 'none',
                                hiddenUntilLoaded: false,
                                padding: adPaddingOptions['thewest'],
                                slot: {
                                    id: 'outstream2x2',
                                    size: 'outstream',
                                },
                                adType: 'inline',
                            },
                        }),

                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'perthnow-topic-accordion',
                                        props: {
                                            topics: article.topics,
                                            perthNowSectionNavItems:
                                                getPerthNowSectionNav(),
                                            subTopicNavItems:
                                                getPerthNowSubTopicNavItems(),
                                        },
                                    }),
                                ],
                            },
                        }),

                        layout.component({
                            type: 'article',
                            props: {
                                meta: {
                                    slug: article.slug,
                                    kind: 'article',
                                    inlinePublicationContent: [
                                        ...extraInlineArticleAds,
                                        ...(article.kind === 'event'
                                            ? getInlineEventAds(
                                                  Product.PerthNow,
                                              )
                                            : []),
                                    ],
                                },
                                dataDefinitionArgs: {
                                    publicationId: article.id,
                                },
                                section,
                            },
                        }),
                        layout.component({
                            type: 'coral',
                            feature: 'comments',
                            props: {
                                storyID: `${config.publicHostname}-${article.id}`,
                                articleCommentsEnabled: article.allowCommenting,
                                storyURL: `${article._self}`,
                            },
                        }),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-top',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'pn-newsletter-banner-ngn',
                            props: {
                                marginBottom: 32,
                                marginTop: 32,
                            },
                        }),
                        sidebarCollection(),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [13, 0],
                                stickyOffset: 80,

                                slot: {
                                    id: 'sidebar-middle',
                                    size: 'desktopMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                    ],
                },
            }),
            // Taboola Feed
            layout.composition({
                type: 'box',
                props: {},
                feature: 'taboola',
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-article-thumbnails',
                                locationType: 'article',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
