import {
    CardBreakpointRatios,
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
    FixedRatio,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { TeaserMode } from '../../cards/CardText/CardTeaser'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import { invertMaybeLoadedItems } from '../helpers/loading'
import { HeaderSectionTagType, PNArticleCard } from '../../nextgen-news'
import { StyledOtis } from './PNOtis.styled'

export interface LargeCard {
    largeCardPosition?: 'left' | 'right' | undefined
    largeCardType?: 'portrait' | 'landscape' | undefined
}

export interface PNOtisProps extends ResponsiveContainer {
    className?: string
    fixedRatios?: FixedRatio[] | CardBreakpointRatios
    largeCard?: LargeCard
    teaserMode: TeaserMode
    onEvent: (event: CollectionEvent) => void
    disableImageLazyLoad?: boolean
    expectedCards: number
    items: MaybeLoaded<CardItem[]>
    canPlayVideoInline?: boolean
    showAuthor?: boolean
    headerSectionTag?: HeaderSectionTagType
}

export const PNOtis: React.FC<PNOtisProps> = (props) => {
    const {
        className,
        largeCard,
        onEvent,
        disableImageLazyLoad,
        fixedRatios,
        expectedCards,
        canPlayVideoInline,
        showAuthor,
        headerSectionTag,
    } = props
    const product = useProduct()

    const items = invertMaybeLoadedItems(props.items, expectedCards)
    // We only need three (One large card + 2 next to it)
    const numberSlicedItems = 3
    const numItems = items.slice(numberSlicedItems)

    const largeOtisCard = (cardNumber: number) =>
        items.slice(0, 1).map((item, index) => (
            <CardSwitcher
                onEvent={onEvent}
                key={index}
                item={item}
                cardContext="pn-otis-large"
                cardNumber={cardNumber}
                publicationCard={(publicationItem) => (
                    <PNArticleCard
                        fontScale="M"
                        cardType={
                            largeCard?.largeCardType === 'landscape'
                                ? 'horizontal'
                                : 'vertical'
                        }
                        item={publicationItem}
                        onEvent={onEvent}
                        fixedRatio={canPlayVideoInline ? '16:9' : '4:3'}
                        disableImageLazyLoad={disableImageLazyLoad}
                        cardNumber={cardNumber}
                        teaserMode="visible"
                        teaserFontScale="XL"
                        headerSectionTag={headerSectionTag}
                        showAuthor={showAuthor}
                        kickerMode={
                            largeCard?.largeCardType === 'landscape'
                                ? 'middle'
                                : 'top'
                        }
                        headerFontOverride={{
                            mobile: 'M',
                            tablet: 'M',
                            desktop: 'M',
                        }}
                    />
                )}
            />
        ))

    /* Two Landscape Stacked cards */
    const stackedOtisCards = (cardNumber: number) => {
        return items.slice(1, numberSlicedItems).map((item, index) => (
            <CardSwitcher
                onEvent={onEvent}
                key={index}
                item={item}
                cardContext="pn-otis-stacked"
                cardNumber={cardNumber + index}
                publicationCard={(publicationItem) => (
                    <PNArticleCard
                        cardType="horizontal"
                        item={publicationItem}
                        onEvent={onEvent}
                        disableImageLazyLoad={disableImageLazyLoad}
                        teaserMode={props.teaserMode}
                        fixedRatio={fixedRatios}
                        cardNumber={cardNumber + index}
                        fontScale={'M'}
                        showAuthor={showAuthor}
                        headerSectionTag={'h3'}
                        teaserFontScale={'XL'}
                        headerFontOverride={{
                            mobile: 'S',
                            tablet: 'M',
                            desktop: 'M',
                        }}
                    />
                )}
            />
        ))
    }

    // Determine whether the large card is positioned on the left or right hand side.
    const getCardPosition = (
        position: 'left' | 'right' | undefined,
        hasBackground?: boolean,
    ) => {
        if (position === 'right') {
            return (
                <>
                    {stackedOtisCards(1)} {largeOtisCard(numberSlicedItems)}
                </>
            )
        }

        return (
            <>
                {largeOtisCard(1)} {stackedOtisCards(2)}
            </>
        )
    }

    const styledOtis = (
        <ImpressionAvailable
            loading={!props.items.loaded}
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                props.onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'Otis',
                        product,
                        props.onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledOtis
                    ref={ref}
                    className={className}
                    largeCardSpan={2}
                    largeCardPosition={
                        largeCard?.largeCardPosition
                            ? largeCard.largeCardPosition
                            : 'right'
                    }
                >
                    {/* Set up a new row and slice items for large card display*/}
                    {getCardPosition(largeCard?.largeCardPosition)}
                    {/* Remaining cards using landscape stacked card */}
                    {numItems.length > 0 &&
                        numItems.map((item, index) => {
                            const innerCardNum = index + numberSlicedItems + 1

                            return (
                                <CardSwitcher
                                    onEvent={onEvent}
                                    key={index}
                                    item={item}
                                    cardContext="pn-otis-remaining"
                                    cardNumber={innerCardNum}
                                    publicationCard={(publicationItem) => (
                                        <PNArticleCard
                                            cardType="horizontal"
                                            item={publicationItem}
                                            onEvent={onEvent}
                                            disableImageLazyLoad={
                                                disableImageLazyLoad
                                            }
                                            teaserMode={props.teaserMode}
                                            fixedRatio={fixedRatios}
                                            cardNumber={innerCardNum}
                                            fontScale={'M'}
                                            showAuthor={showAuthor}
                                            headerSectionTag={'h3'}
                                        />
                                    )}
                                />
                            )
                        })}
                </StyledOtis>
            )}
        </ImpressionAvailable>
    )

    return styledOtis
}
PNOtis.displayName = 'PNOtis'
